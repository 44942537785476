import { connect } from 'react-redux';
import type { Tag } from '../../../../../types';
import { getAllTagsInAccount } from '../../../../Drivers.selectors';
import { DriverTagsInRow } from '../components/DriverTagsInRow';
import type { RootState } from '../../../../../configuration/setup/store';

export interface DriverTagsInRowPropertiesFromState {
    allTagsInAccount: Tag[];
}

const mapStateToProps = (state: RootState): DriverTagsInRowPropertiesFromState => ({
    allTagsInAccount: getAllTagsInAccount(state),
});

export const DriverTagsInRowContainer = connect(mapStateToProps)(DriverTagsInRow);
