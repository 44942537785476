import type { RouteState } from './types';
import qs from 'qs';
import { STRINGIFY_OPTIONS } from './qsUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import useAfterMount from '@rio-cloud/rio-uikit/useAfterMount';

export const makeRoute = (searchParams: RouteState): string => {
    const queryParams = qs.stringify(searchParams, STRINGIFY_OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;
    return encodeURI(searchFragment);
};

export const useUpdateRoute = (newSearch: string) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    useAfterMount(() => {
        if (pathname + search !== newSearch) {
            navigate(newSearch);
        }
    }, [newSearch]);
};
