import { type Driver, DriverStatus } from '../../../types';
import { saveDrivers } from './saveDriverThunks';
import { fetchDriver } from './fetchThunks';
import type { AppDispatch } from '../../../configuration/setup/store';

export const archiveDrivers =
    (currentDriverId: string | null, drivers: Driver[], removeUsers: boolean) => async (dispatch: AppDispatch) => {
        const updatedDrivers: Driver[] = drivers.map(driver => {
            if (removeUsers) {
                return {
                    ...driver,
                    user: undefined,
                    subject: undefined,
                    status: DriverStatus.ARCHIVED,
                };
            } else {
                return {
                    ...driver,
                    status: DriverStatus.ARCHIVED,
                };
            }
        });
        await dispatch(saveDrivers(updatedDrivers));
        if (currentDriverId !== null) {
            await dispatch(fetchDriver(currentDriverId));
        }
    };

export const activateDrivers = (currentDriverId: string | null, drivers: Driver[]) => async (dispatch: AppDispatch) => {
    const updatedDrivers: Driver[] = drivers.map(driver => ({
        ...driver,
        status: DriverStatus.ACTIVE,
    }));
    await dispatch(saveDrivers(updatedDrivers));
    if (currentDriverId !== null) {
        await dispatch(fetchDriver(currentDriverId));
    }
};
