import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import type {
    DeleteUserDialogPropsFromDispatch,
    DeleteUserDialogPropsFromState,
} from '../containers/DeleteUserDialogContainer';

export type DeleteUserDialogProps = DeleteUserDialogPropsFromState & DeleteUserDialogPropsFromDispatch;

export const DeleteUserDialog = (props: DeleteUserDialogProps) => {
    const { showUsersDeleteDialog, usersToDelete, deleteUser, toggleDeleteUserDialog, setUsersToDelete, fetchUsers } =
        props;
    const multipleUsers = usersToDelete.length > 1;

    const onConfirm = () => {
        for (const user of usersToDelete) {
            deleteUser(user);
        }
        toggleDeleteUserDialog(false);
        setUsersToDelete([]);
        fetchUsers();
    };

    const onCancel = () => {
        setUsersToDelete([]);
        toggleDeleteUserDialog(false);
    };

    return (
        <div data-testid={'delete-user-dialog'}>
            <ConfirmationDialog
                show={showUsersDeleteDialog}
                bsSize={'sm'}
                useOverflow={true}
                title={<FormattedMessage id={`intl-msg:dialog.deleteUser${multipleUsers ? 's' : ''}.title`} />}
                content={
                    <FormattedMessage
                        id={`intl-msg:dialog.deleteUser${multipleUsers ? 's' : ''}.deleteAssignedUser${
                            multipleUsers ? 's' : ''
                        }`}
                    />
                }
                onClickConfirm={onConfirm}
                onClickCancel={onCancel}
                cancelButtonText={
                    <div data-testid={'cancelDeleteUser'}>
                        <FormattedMessage id={'intl-msg:no'} />
                    </div>
                }
                confirmButtonText={
                    <div data-testid={'confirmDeleteUser'}>
                        <FormattedMessage id={'intl-msg:yes'} />
                    </div>
                }
            />
        </div>
    );
};
