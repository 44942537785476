export const includeGoogleTagManager = (accountId: string | undefined) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
        account: accountId,
        tenant: import.meta.env.PROD ? 'prod' : 'local',
    });

    ((w: any, doc: any) => {
        const gtmId = w.location.host === 'drivers.rio.cloud' ? 'GTM-WXRZGJD' : 'GTM-TS28DBN';
        const dataLayer = 'dataLayer';

        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        const f = doc.getElementsByTagName('script')[0];
        const j = doc.createElement('script');
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        f.parentNode.insertBefore(j, f);
    })(window, document);
};
