import type React from 'react';
import { reportErrorToSentry } from './configuration/setup/sentry';
import type { messagesEN } from './test/testUtils';
import type { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import type { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

export interface AppState {
    inviteUserRequested: boolean;
    drivers: Driver[];
    driverCreation: DriverCreationDialogState;
    driversListMode: TableViewTogglesViewType;
    searchText: string;
    sortDir: SortDirectionType;
    sortBy: string;
    selectedDriverId: string | null;
    driverStatuses: DriverStatus[];
    fetchDriversRequested: boolean;
    // TODO remove when only using errorCode
    hasFetchError: boolean;
    users: User[];
    sessionWarningAcknowledged: boolean;
}

export interface DriverCreationDialogState {
    showCreationDialog: boolean;
    inProgress: boolean;
}

export interface UserDialogState {
    userDialog: {
        showDialog: boolean;
        currentStep: CreateDialogStepName;
    };
    userDeletionDialog: {
        showDialog: boolean;
        usersToDelete: User[];
    };
}

export interface GroupsState {
    tagsToAdd: Tag[];
    tagsToCreate: TagToCreate[];
    tagsToRemove: Tag[];
    allTagsInAccount: Tag[];
    multiSelect: {
        tagsToAdd: Tag[];
        tagsToCreate: TagToCreate[];
        tagsToRemove: Tag[];
    };
}

export interface NewIdentification {
    value: string | null;
    isValid: boolean;
    type: IdentificationTypes | undefined;
}

export interface SidebarState {
    hasChanges: boolean;
    formErrors: {
        [key: string]: string | undefined; // lastName: 'intl-msg:error.lastName.required'
    };
    selectedDriver: Driver | null;
    selectedDriverChanges: DriverChanges;
    selectedDriverWithoutEdits: Driver | null;
    pendingIdentificationDeletions: string[];
    newIdentification: NewIdentification | null;
    showNotificationCreation: boolean;
    contentId: SidebarContentId;
    fetchDriverRequested: boolean;
    inviteUserRequested: boolean;
    showUnsavedChangesDialog: boolean;
    pendingTabChangeNextTab: SidebarContentId | null;
}

export interface User {
    id: string; // uuid
    accountId?: string; // uuid
    firstName: string; // 'Renate🚨',
    lastName: string; // 'Fahrerin',
    email?: string; // test.user@rio.cloud
    phoneNumber?: string;
    preferredLanguage?: string;
    subject?: string; // prod-rio-users:uuid
}

export interface UserWithGroupIds extends User {
    groupIds: string[];
}

export interface ApiDriver {
    id: string;
    account_id: string;
    first_name: string;
    last_name: string;
    phone_number?: string;
    email?: string;
    subject?: string;
    status: string;
}

export interface ApiUser {
    id: string;
    account_id?: string;
    first_name: string;
    last_name: string;
    email?: string;
    phone_number?: string;
    group_ids: string[];
    preferred_language?: string;
    subject?: string;
}

export interface UserError {
    status: number;
    detail: string;
}

export enum IdentificationTypes {
    EU_TACHOGRAPH_CARD = 'eu-tachograph-card',
    COUNTRY_SPECIFIC = 'country-specific-driver-license',
}
export const allIdentificationTypes: string[] = Object.values(IdentificationTypes);
export const parseIdentificationType = (input: string): IdentificationTypes => {
    switch (input) {
        case IdentificationTypes.EU_TACHOGRAPH_CARD.toString():
            return IdentificationTypes.EU_TACHOGRAPH_CARD;
        case IdentificationTypes.COUNTRY_SPECIFIC.toString():
            return IdentificationTypes.COUNTRY_SPECIFIC;
        default:
            reportErrorToSentry(`Unknown identification type: ${input}`);
            throw Error(`Unknown identification type: ${input}`);
    }
};

export interface Identification {
    id: string;
    type: IdentificationTypes;
    value: string;
}

export interface ApiIdentification {
    id: string;
    identification_type: string;
    identification: string;
}

export interface Tag {
    id: string;
    type: string;
    name: string;
    accountId: string;
}

export interface ApiTag {
    id: string;
    type: string;
    name: string;
    account_id: string;
}

export interface ApiUntag {
    tagId: string;
    driverId: string;
}

export interface ApiCreateTag {
    tagName: string;
    accountId: string;
}

export interface ApiFetchTag {
    accountId: string;
    tagsQuery: string;
}

export interface ApiFetchDriver {
    driverId: string;
}

export interface UserGroup {
    id: string;
    name: string;
}

export interface ApiUserGroup {
    id: string;
    name: string;
}

export interface ApiUserInvite {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    status: string;
    phoneNumber: string;
    accountId: string;
    registrationDate: number;
    newsletterApproved: boolean;
    preferredLanguage: string;
    userGroupIds: string[];
    userRoles: string[];
    subject: string;
}

export interface ResponseType {
    body: any;
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

export interface Driver {
    driverId: string; // uuid
    accountId: string; // uuid
    firstName: string;
    lastName: string;
    status: DriverStatus;
    activeCardNumber: string; // 'RIO DF1000000000001'
    otherCardNumbers: string[]; // 'RIO DF1000000000001'
    identifications: Identification[];
    email?: string;
    phoneNumber?: string;
    subject?: string; // 'prod-rio-users:uuid
    user?: User | undefined;
    tags?: string[]; // uuid
}

export interface DriverChanges {
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    phoneNumber: boolean;
    status: boolean;
    user: boolean;
    pendingIdentificationDeletions: boolean;
    pendingNewIdentification: boolean;
}

export interface DriverToCreate {
    driverId: string;
    accountId: string;
    firstName?: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
    status: DriverStatus;
    identification: Identification;
}

export interface UserGroup {
    id: string;
    name: string;
}

export interface Tag {
    id: string; // uuid
    type: string; // user
    name: string; // camelCaseGroup
    accountId: string; // uuid
}

export interface TagToCreate {
    name: string; // camelCaseGroup
}

export enum SortByField {
    FIELD_FIRST_NAME = 'firstName',
    FIELD_LAST_NAME = 'lastName',
    FIELD_CARD = 'card',
    FIELD_STATUS = 'status',
    FIELD_EMAIL = 'email',
    FIELD_PHONE = 'phoneNumber',
    FIELD_USER = 'user',
    FIELD_TAG = 'tags',
}

export enum DriverStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
}

export const intlDriverStatus = (status: DriverStatus): string => {
    return `intl-msg:status.${status.toString()}`;
};

export const parseDriverStatus = (input: string): DriverStatus => {
    switch (input) {
        case DriverStatus.ACTIVE.toString():
            return DriverStatus.ACTIVE;
        case DriverStatus.ARCHIVED.toString():
            return DriverStatus.ARCHIVED;
        default:
            reportErrorToSentry(`Unknown driver status: ${input}`);
            throw Error(`Unknown driver status: ${input}`);
    }
};

export enum CreateDialogStepName {
    USER_MODE_SELECTION = 'USER_MODE_SELECTION',
    CREATE_NEW_USER = 'CREATE_NEW_USER',
    CREATE_USER_BY_EMAIL = 'CREATE_USER_BY_EMAIL',
    CREATE_USER_BY_PHONE = 'CREATE_USER_BY_PHONE',
    ASSIGN_USER = 'ASSIGN_USER',
}

export enum SidebarContentId {
    INFO = 'info',
    USER = 'user',
    GROUP = 'group',
}

export interface CreateDialogStep {
    headline: React.ReactFragment | React.ReactElement;
    body: React.ReactFragment | React.ReactElement;
    showDialogBackButton: boolean;
    showDialogNextButton: boolean;
    backButtonText: React.ReactFragment | React.ReactElement;
    nextButtonText: React.ReactFragment | React.ReactElement;
    backClickHandler: () => void;
    nextClickHandler: () => void;
    disabled: boolean;
}

export enum DialogState {
    SHOW = 'show',
}

export type PhraseId = keyof typeof messagesEN;

export interface DriverIdentificationFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    parentDialogShown: boolean;
    existingDrivers: Driver[];
    onIdentificationChangeCallback: (newIdentification: NewIdentification) => void;
    currentDriver: Driver | null;
}
