import type { ThunkDispatch } from 'redux-thunk';
import type { Driver, DriverToCreate } from '../../../types';
import { api } from '../../api';
import { ErrorOrigin, type ErrorResponse, getErrorCode } from '../../apiUtils';
import { showErrorNotification, showSuccessNotification } from '../notifications/notifications';
import { fetchDrivers } from './fetchThunks';
import { driverCreationFinished, driverCreationStarted } from '../appSlice';
import { driverSelectedThunk } from '../../crossSliceThunks';

export const createAndSelectDriver =
    (driverToCreate: DriverToCreate) => async (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(driverCreationStarted());
        let successfulDriverCreation = false;
        try {
            await api.saveDriver(mapToDriver(driverToCreate));
            successfulDriverCreation = true;
        } catch (errorResponse) {
            showErrorNotification(getErrorCode(errorResponse as ErrorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
            dispatch(driverCreationFinished());
        }
        if (successfulDriverCreation) {
            try {
                const response = await api.addIdentificationToDriver(
                    driverToCreate.driverId,
                    driverToCreate.identification
                );
                showSuccessNotification('intl-msg:save.success');

                return response;
            } catch (errorResponse) {
                showErrorNotification(getErrorCode(errorResponse as ErrorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
            } finally {
                // fetch list of drivers again as the state may have changed
                await dispatch(fetchDrivers());
                dispatch(driverSelectedThunk(driverToCreate.driverId));
                dispatch(driverCreationFinished());
            }
        }
    };

// currently the api.saveDriver method expects a driver object
export const mapToDriver = (driverToCreate: DriverToCreate): Driver => {
    return {
        driverId: driverToCreate.driverId,
        accountId: driverToCreate.accountId,
        firstName: driverToCreate.firstName ?? '',
        lastName: driverToCreate.lastName,
        email: driverToCreate.email,
        phoneNumber: driverToCreate.phoneNumber,
        status: driverToCreate.status,
        activeCardNumber: driverToCreate.identification.value,
        otherCardNumbers: [],
        identifications: [driverToCreate.identification],
        tags: [],
    };
};
