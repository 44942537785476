export const enumFromEnumKey = <T>(type: T, key: string | undefined): T[keyof T] | undefined => {
    if (key === undefined) {
        return undefined;
    }
    const casted = key as keyof T;
    return type[casted];
};

export const enumFromEnumValue = <T>(type: T, value: string | undefined): T[keyof T] | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [enumKey] = Object.entries(type as any).find(([_, enumValue]) => enumValue === value) || [undefined];
    return enumFromEnumKey(type, enumKey);
};
