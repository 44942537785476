import { api } from '../../api';
import { showSuccessNotification, showErrorNotification } from '../notifications/notifications';
import { ErrorOrigin, type ErrorResponse, getErrorCode } from '../../apiUtils';

export const createTag = (tagName: string, accountId: string) => {
    return api
        .createTag({
            tagName,
            accountId,
        })
        .then((response: Response) => {
            showSuccessNotification('intl-msg:tag.save.success');
            return response;
        })
        .catch((errorResponse: ErrorResponse) => {
            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.TAG_SERVICE));
        });
};

export const tagDriver = (tagId: string, driverId: string) => {
    return api
        .tagDriver({
            tagId,
            driverId,
        })
        .catch((errorResponse: ErrorResponse) => {
            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
        });
};

export const untagDriver = (tagId: string, driverId: string) => {
    return api
        .untagDriver({
            tagId,
            driverId,
        })
        .catch((errorResponse: ErrorResponse) => {
            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
        });
};
