import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Tag, TagToCreate } from '../../../../../../types';

export interface GroupsState {
    tagsToAdd: Tag[];
    tagsToCreate: TagToCreate[];
    tagsToRemove: Tag[];
    allTagsInAccount: Tag[];
    multiSelect: {
        tagsToAdd: Tag[];
        tagsToCreate: TagToCreate[];
        tagsToRemove: Tag[];
    };
}

export const initialState = (): GroupsState => ({
    tagsToAdd: [],
    tagsToCreate: [],
    tagsToRemove: [],
    allTagsInAccount: [],
    multiSelect: {
        tagsToAdd: [],
        tagsToCreate: [],
        tagsToRemove: [],
    },
});

const groupSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        tagsFetched: (state, action: PayloadAction<Tag[]>) => {
            state.allTagsInAccount = action.payload || [];
        },
        clearAllTags: state => {
            state.tagsToAdd = [];
            state.tagsToCreate = [];
            state.tagsToRemove = [];
        },
        setMultiSelectTagsToCreate: (state, action: PayloadAction<TagToCreate[]>) => {
            state.multiSelect.tagsToCreate = action.payload;
        },
        setMultiSelectTagToAdd: (state, action: PayloadAction<Tag[]>) => {
            state.multiSelect.tagsToAdd = action.payload;
        },
        setMultiSelectTagsToRemove: (state, action: PayloadAction<Tag[]>) => {
            state.multiSelect.tagsToRemove = action.payload;
        },
        clearAllMultiSelectTags: state => {
            state.multiSelect.tagsToAdd = [];
            state.multiSelect.tagsToCreate = [];
            state.multiSelect.tagsToRemove = [];
        },
        setTagsToCreate: (state, action: PayloadAction<TagToCreate[]>) => {
            state.tagsToCreate = action.payload;
        },
        setTagsToAdd: (state, action: PayloadAction<Tag[]>) => {
            state.tagsToAdd = action.payload;
        },
        setTagsToRemove: (state, action: PayloadAction<Tag[]>) => {
            state.tagsToRemove = action.payload;
        },
    },
});

export const {
    tagsFetched,
    clearAllTags,
    setMultiSelectTagsToCreate,
    setMultiSelectTagToAdd,
    setMultiSelectTagsToRemove,
    clearAllMultiSelectTags,
    setTagsToCreate,
    setTagsToAdd,
    setTagsToRemove,
} = groupSlice.actions;

export default groupSlice.reducer;
