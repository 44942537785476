import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';
import type { Driver } from '../../../types';
import { formatDriverNameForDisplay } from '../utils';

export const showSuccessNotification = (message: string) => {
    Notification.success(<FormattedMessage id={message} />);
};

export const showDeleteDriverSuccessNotification = (driver: Driver) => {
    Notification.success(
        <FormattedMessage
            id={'intl-msg:driver.delete.success'}
            values={{ driverName: formatDriverNameForDisplay(driver) }}
        />
    );
};

export const showErrorNotification = (errorCode: string | null, errorFromUserAdministration = false) => {
    if (errorFromUserAdministration) {
        return nonVanishingErrorNotification(<FormattedMessage id={`${errorCode}`} />, 'UXX');
    } else {
        return nonVanishingErrorNotification(
            <FormattedMessage id={`intl-msg:error.fetch.message.${errorCode}`} />,
            errorCode
        );
    }
};

function nonVanishingErrorNotification(
    message: string | JSX.Element | JSX.Element[],
    errorCode: string | null,
    dataAttribute?: string
): any {
    return Notification.error(
        <span data-cy={dataAttribute} data-testid={'notification-error'}>
            {message}
            <hr />
            {`Error Code: ${errorCode}`}
            <span className={'notification-close'}>
                <span className='rioglyph rioglyph-remove' />
            </span>
        </span>,
        '',
        1000 * 60 * 15,
        () => {}
    );
}
