import { FormattedMessage } from 'react-intl';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import TagManager, { type TagManagerTag } from '@rio-cloud/rio-uikit/TagManager';
import type { Tag } from '../../../../../../../types';
import type { DriverGroupsFormProperties } from '../../types/driverGroupsFormTypes';

export const findAllTagsInAccountForDriver = (allTagsInAccount: Tag[], assignedTagIds: string[]) => {
    return allTagsInAccount.filter((tag: Tag) => assignedTagIds.includes(tag.id));
};

export const DriverGroupsForm = (props: DriverGroupsFormProperties) => {
    const { assignedTagIds, allTagsInAccount, setTags } = props;

    const tagsForDriver = findAllTagsInAccountForDriver(allTagsInAccount, assignedTagIds).map(tag => ({
        label: tag.name,
    }));

    const getFormattedMessagePlaceholder = () => {
        return props.intl.formatMessage({ id: 'intl-msg:tag.placeholder' });
    };

    const getFormattedMessageCustomTagPlaceholder = () => {
        return props.intl.formatMessage({ id: 'intl-msg:tag.customTagPlaceholder' });
    };

    const getFormattedMessageDropdownSeparatorText = () => {
        return props.intl.formatMessage({ id: 'intl-msg:tag.dropdownSeparatorText' });
    };

    const getFormattedMessageNoItemMessage = () => {
        return props.intl.formatMessage({ id: 'intl-msg:tag.noItemMessage' });
    };

    const onTagListChange = (tagManagerTagProps: TagManagerTag[]) => {
        const tagsToAdd = allTagsInAccount.filter(tag =>
            tagManagerTagProps
                .filter(it => it.toAdd)
                .map(it => it.label)
                .includes(tag.name)
        );

        const labels = tagManagerTagProps.filter(prop => prop.label !== undefined).map(it => it.label as string);
        const tagsToCreate = labels
            .filter(label => !allTagsInAccount.map(it => it.name).includes(label))
            .map(label => ({ name: label }));

        const tagNamesToRemove = tagManagerTagProps.filter(it => it.toRemove).map(it => it.label);
        const tagsToRemove = allTagsInAccount.filter(tag => tagNamesToRemove.includes(tag.name));
        setTags({ tagsToAdd, tagsToCreate, tagsToRemove });
    };

    return (
        <>
            <CustomState
                headline={<FormattedMessage id={'intl-msg:tag'} />}
                message={
                    <div data-cy='tag-list'>
                        <div className={'margin-bottom-20'}>
                            <FormattedMessage id={'intl-msg:tag.customMessage'} />
                        </div>
                        <TagManager
                            tagList={tagsForDriver}
                            tagSuggestions={allTagsInAccount.map((tag: Tag) => ({ label: tag.name }))}
                            onTagListChange={onTagListChange}
                            placeholder={getFormattedMessagePlaceholder()}
                            customTagPlaceholder={getFormattedMessageCustomTagPlaceholder()}
                            dropdownSeparatorText={getFormattedMessageDropdownSeparatorText()}
                            noItemMessage={getFormattedMessageNoItemMessage()}
                            useCustomTags={true}
                            showInput={true}
                        />
                    </div>
                }
                icons={[
                    {
                        name: 'rioglyph rioglyph-van',
                        className: 'text-size-200pct',
                    },
                    {
                        name: 'rioglyph rioglyph-truck',
                        className: 'text-size-300pct',
                    },
                    {
                        name: 'rioglyph rioglyph-driver',
                        className: 'text-size-300pct',
                    },
                    {
                        name: 'rioglyph rioglyph-trailer',
                        className: 'text-size-200pct',
                    },
                ]}
                outerClassName={'margin-bottom-15 bg-highlight-decent'}
                condensed={true}
            />
        </>
    );
};
