import CustomState from '@rio-cloud/rio-uikit/CustomState';
import TagManager, { type TagManagerTag } from '@rio-cloud/rio-uikit/TagManager';
import { FormattedMessage } from 'react-intl';
import type {
    DriverMultiSelectRemoveDialogBodyPropertiesFromDispatch,
    DriverMultiSelectRemoveDialogBodyPropertiesFromState,
} from '../containers/DriverMultiSelectRemoveDialogBodyContainer';

interface DriverMultiSelectRemoveDialogBodyOwnProps {
    selectedDriverIds: string[];
}

export type DriverMultiSelectRemoveDialogBodyProperties = DriverMultiSelectRemoveDialogBodyPropertiesFromState &
    DriverMultiSelectRemoveDialogBodyPropertiesFromDispatch &
    DriverMultiSelectRemoveDialogBodyOwnProps;

export const DriverMultiSelectRemoveDialogBody = (props: DriverMultiSelectRemoveDialogBodyProperties) => {
    const { selectedDriverIds, allTagsInAccount, setTagsToRemove, drivers } = props;

    const getSelectedDrivers = () => {
        return drivers.filter(driver => selectedDriverIds.includes(driver.driverId));
    };

    const getTagsOfDrivers = () => {
        const combinedTagIdsOfSelectedDrivers = getSelectedDrivers().flatMap(driver =>
            driver.tags ? driver.tags : []
        );
        return allTagsInAccount.filter(tag => combinedTagIdsOfSelectedDrivers.includes(tag.id));
    };

    const onTagListChangeForRemoval = (tagManagerTagProps: TagManagerTag[]) => {
        const tagNamesToRemove = tagManagerTagProps.filter(it => it.toRemove).map(it => it.label);
        const tagsToRemove = allTagsInAccount.filter(tag => tagNamesToRemove.includes(tag.name));
        setTagsToRemove(tagsToRemove);
    };

    return (
        <CustomState
            headline={<FormattedMessage id={'intl-msg:tag'} />}
            message={
                <div data-cy='tag-list'>
                    <div className={'margin-bottom-20'}>
                        <FormattedMessage id={'intl-msg:tag.removeFromGroups'} />
                    </div>
                    <TagManager
                        tagList={getTagsOfDrivers().map(tag => ({ label: tag.name }))}
                        tagSuggestions={[]}
                        onTagListChange={onTagListChangeForRemoval}
                        placeholder={''}
                        customTagPlaceholder={''}
                        dropdownSeparatorText={''}
                        noItemMessage={''}
                        useCustomTags={true}
                        showInput={false}
                    />
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-van',
                    className: 'text-size-200pct',
                },
                {
                    name: 'rioglyph rioglyph-truck',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph rioglyph-trailer',
                    className: 'text-size-200pct',
                },
            ]}
            outerClassName={'margin-bottom-15 bg-highlight-decent'}
            condensed={true}
        />
    );
};
