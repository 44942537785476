import type { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Drivers } from '../components/Drivers';
import type { Driver } from '../../../../../types';
import { getSelectedDriverRtk } from '../../sidebar/sidebarSlice';

export interface DriversPropertiesFromState {
    selectedDriver: Driver | null;
    showDeleteUsersDialog: boolean;
    header: ReactElement;
}

const mapStateToProps = (state: any, ownProps: any) => ({
    selectedDriver: getSelectedDriverRtk(state),
    header: ownProps.header,
});

export const DriversContainer = connect(mapStateToProps)(Drivers);
