import type { Driver, Tag } from '../../../../../types';
import { connect } from 'react-redux';
import { getAllTagsInAccount } from '../../../../Drivers.selectors';
import { DriverMultiSelectRemoveDialogBody } from '../components/DriverMultiSelectRemoveDialogBody';
import { getDriversRtk } from '../../../appSlice';
import type { AppDispatch, RootState } from '../../../../../configuration/setup/store';
import { setMultiSelectTagsToRemove } from '../../sidebar/tabs/groups/groupSlice';

export interface DriverMultiSelectRemoveDialogBodyPropertiesFromState {
    allTagsInAccount: Tag[];
    drivers: Driver[];
}

export interface DriverMultiSelectRemoveDialogBodyPropertiesFromDispatch {
    setTagsToRemove: (tag: Tag[]) => void;
}

const mapStateToProps = (state: RootState): DriverMultiSelectRemoveDialogBodyPropertiesFromState => ({
    allTagsInAccount: getAllTagsInAccount(state),
    drivers: getDriversRtk(state as any),
});

const mapDispatchToProps = (dispatch: AppDispatch): DriverMultiSelectRemoveDialogBodyPropertiesFromDispatch => ({
    setTagsToRemove(tags: Tag[]) {
        dispatch(setMultiSelectTagsToRemove(tags));
    },
});

export const DriverMultiSelectRemoveDialogBodyContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DriverMultiSelectRemoveDialogBody);
