import { v4 as uuidv4 } from 'uuid';
import type { Identification, NewIdentification } from '../../../types';
import { api } from '../../api';
import { showSuccessNotification } from '../notifications/notifications';

export const addIdentification = async (driverId: string, newIdentification: NewIdentification | null) => {
    if (newIdentification?.value && newIdentification.type) {
        const identification: Identification = {
            id: uuidv4(),
            value: newIdentification.value,
            type: newIdentification.type,
        };
        return api.addIdentificationToDriver(driverId, identification).then((response: Response) => {
            showSuccessNotification('intl-msg:identification.add.success');
            return response;
        });
    }
    return Promise.resolve();
};

export const deleteIdentifications = async (driverId: string, identificationIds: Set<string>) => {
    for (const identificationId of Array.from(identificationIds)) {
        await deleteIdentification(driverId, identificationId);
    }
};

export const deleteIdentification = async (driverId: string, identificationId: string) => {
    return api.deleteIdentificationOfDriver(driverId, identificationId).then((response: Response) => {
        showSuccessNotification('intl-msg:identification.deleted.success');
        return response;
    });
};
