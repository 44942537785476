import TagList from '@rio-cloud/rio-uikit/TagList';
import Tag from '@rio-cloud/rio-uikit/Tag';
import type React from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import { prop } from 'ramda';
import { FormattedMessage } from 'react-intl';
import {
    INTL_FIELD_CARD,
    INTL_FIELD_EMAIL,
    INTL_FIELD_FIRST_NAME,
    INTL_FIELD_LAST_NAME,
    INTL_FIELD_PHONE,
    INTL_FIELD_TAG,
    INTL_FIELD_USER,
    PROP_DRIVER_ID,
    PROP_PHONE_NUMBER,
} from '../../../schema';
import { formatUserNameForDisplay } from '../../../utils';
import { Telephone } from './Telephone';
import { Email } from './Email';
import { DriverTagsInRowContainer } from '../containers/DriverTagsInRowContainer';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { type Driver, SortByField, type User } from '../../../../../types';
import { config } from '../../../../../config';
import { Link } from './Link';
import { DriverStatusHint } from './DriverStatusHint';

const keyProp = prop(PROP_DRIVER_ID);

const handleLinkClick: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
    event.stopPropagation();
};

interface DriversRowProperties {
    driver: Driver;
    onSelectionChange: (event: React.MouseEvent) => void;
    selectedDriverIdsForMultiSelect: string[];
    rowActive: boolean;
    user?: User;
}

export const DriversRow = memo((props: DriversRowProperties) => {
    const { driver, onSelectionChange, selectedDriverIdsForMultiSelect, rowActive, user } = props;

    return (
        <tr
            data-key={keyProp(driver)}
            key={keyProp(driver)}
            onClick={onSelectionChange}
            data-testid={'driverRow'}
            className={classNames(rowActive && 'active')}
        >
            <td
                className='table-checkbox'
                data-testid={
                    selectedDriverIdsForMultiSelect.includes(driver.driverId)
                        ? 'drivers-table-row-check-box-checked'
                        : 'drivers-table-row-check-box-unchecked'
                }
            >
                <Checkbox checked={selectedDriverIdsForMultiSelect.includes(driver.driverId)} />
            </td>
            <td data-field={SortByField.FIELD_STATUS} data-testid={'driverRowStatus'}>
                <DriverStatusHint driverStatus={driver.status} />
            </td>
            <FormattedMessage id={INTL_FIELD_FIRST_NAME}>
                {fieldName => (
                    <td className={'ellipsis-1'} data-field={fieldName}>
                        <span>{driver.firstName}</span>
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_LAST_NAME}>
                {fieldName => (
                    <td className={'ellipsis-1'} data-field={fieldName}>
                        <span>{driver.lastName}</span>
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_CARD}>
                {fieldName => (
                    <td data-field={fieldName}>
                        <TagList>
                            <div>
                                {driver.identifications
                                    .slice()
                                    .sort((a, b) => a.value.localeCompare(b.value))
                                    .map((identification, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Tag key={index} size='small' className={'white-space-nowrap margin-1'}>
                                            {identification.value}
                                        </Tag>
                                    ))}
                            </div>
                        </TagList>
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_EMAIL}>
                {fieldName => (
                    <td className={'ellipsis-1'} data-field={fieldName}>
                        {driver.email && (
                            <span>
                                <Email email={driver.email} onClick={handleLinkClick} />
                            </span>
                        )}
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_PHONE}>
                {fieldName => (
                    <td className={'ellipsis-1'} data-field={fieldName}>
                        {driver.phoneNumber && (
                            <span>
                                <Telephone onClick={handleLinkClick} phoneNumber={driver[PROP_PHONE_NUMBER]} />
                            </span>
                        )}
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_USER}>
                {fieldName => (
                    <td className={'ellipsis-1'} data-field={fieldName}>
                        {driver.subject && (
                            <span>
                                <Link
                                    targetUri={`${config.backend.USERADMIN_WEB}/#/users/${user?.id}`}
                                    text={formatUserNameForDisplay(user)}
                                    onClick={handleLinkClick}
                                />
                            </span>
                        )}
                    </td>
                )}
            </FormattedMessage>
            <FormattedMessage id={INTL_FIELD_TAG}>
                {fieldName => (
                    <td data-field={fieldName} data-testid={'driverRowTags'}>
                        {driver.tags && (
                            <span>
                                <DriverTagsInRowContainer tags={driver.tags} />
                            </span>
                        )}
                    </td>
                )}
            </FormattedMessage>
        </tr>
    );
});
DriversRow.displayName = 'DriversRow';
