import type { ThunkDispatch } from 'redux-thunk';
import { driverIdSchema, driversSortFieldNameSchema, driverStatusFilterSchema, validate } from '../schema';
import { fetchDriver } from './fetchThunks';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import type { DriverStatus } from '../../../types';
import { driverSelected, driverStatusesChanged, sortChanged } from '../appSlice';
import { clearAllTags } from '../drivers/sidebar/tabs/groups/groupSlice';

export const selectDriver = (_oldDriverId: string | undefined, driverId: string) => {
    const { error } = validate(driverIdSchema, driverId);
    if (error) {
        reportErrorToSentry('Validation of driverId failed: ', error);
    }

    return (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(driverSelected(driverId));
        // TODO: refactor, because we no longer have eTags
        // We cannot get rid of this call and optimistically update the Redux store as we need the eTag for the driver.
        dispatch(fetchDriver(driverId));
        dispatch(clearAllTags());
    };
};

export const sortDrivers = (sortBy: string): ((dispatch: ThunkDispatch<any, any, any>) => void) => {
    const { error } = validate(driversSortFieldNameSchema, sortBy);
    if (error) {
        reportErrorToSentry('Validation of sort field failed: ', error);
    }

    return (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(sortChanged({ sortBy }));
    };
};

export const changeDriverStatuses = (driverStatuses: DriverStatus[]) => {
    const { error } = validate(driverStatusFilterSchema, driverStatuses);
    if (error) {
        reportErrorToSentry('Validation of status filter field failed: ', error);
    }

    return (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(driverStatusesChanged(driverStatuses));
    };
};
