import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { SidebarContainer } from '../../sidebar/main/containers/SidebarContainer';
import { TableContainer } from '../../table/main/containers/TableContainer';
import { CreateUserDialogContainer } from '../../sidebar/tabs/user/containers/CreateUserDialogContainer';
import { FormattedMessage } from 'react-intl';
import { RouteUpdater } from '../../../router/RouteUpdater';
import type { DriversPropertiesFromState } from '../containers/DriversContainer';
import { DeleteUserDialogContainer } from '../../../dialogs/containers/DeleteUserDialogContainer';
import { AppContext } from './AppContext';
import { useRef } from 'react';

type DriversProps = DriversPropertiesFromState;

export const Drivers = (props: DriversProps) => {
    const { header, selectedDriver } = props;

    const sidebarRef = useRef<HTMLDivElement>(null);

    const maintenanceMode = false;

    const banner = maintenanceMode ? (
        <ApplicationLayoutBodyBanner>
            <FormattedMessage id={'intl-msg:maintenanceMode'} />
        </ApplicationLayoutBodyBanner>
    ) : null;
    return (
        <AppContext.Provider value={{ sidebarRef }}>
            <ApplicationLayout data-testid='app-layout'>
                {header}
                <ApplicationLayout.Sidebar className={'right'} ref={sidebarRef}>
                    {selectedDriver && <SidebarContainer />}
                </ApplicationLayout.Sidebar>
                <CreateUserDialogContainer />
                <DeleteUserDialogContainer />
                <ApplicationLayout.Body className={'DriverAdmin responsive'} banner={banner}>
                    <NotificationsContainer />
                    <RouteUpdater />
                    <TableContainer />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </AppContext.Provider>
    );
};
