import { api } from '../../api';
import { showErrorNotification } from '../notifications/notifications';
import { tagsFetched } from '../drivers/sidebar/tabs/groups/groupSlice';
import type { Driver, Tag, User } from '../../../types';
import type { ThunkDispatch } from 'redux-thunk';
import type { TableThunkDispatch } from '../drivers/table/main/tableTypes';
import { ErrorOrigin, type ErrorResponse, getErrorCode } from '../../apiUtils';
import { getUserAccount } from '../../../configuration/login/loginSlice';
import isEmpty from 'lodash/isEmpty';
import { driverSelected, driversFetched, fetchDriversFailed, fetchDriversRequested, usersFetched } from '../appSlice';
import {
    fetchDriverRequested,
    fetchSelectedDriverFailed,
    selectedDriverFetched,
} from '../drivers/sidebar/sidebarSlice';
import type { RootState } from '../../../configuration/setup/store';

export const fetchDrivers = () => (dispatch: TableThunkDispatch, getState: () => RootState) => {
    dispatch(fetchDriversRequested());
    const account = getUserAccount(getState());
    if (isEmpty(account)) {
        const error = {
            status: 400,
            detail: 'No account id provided.',
        };
        showErrorNotification(getErrorCode(error, ErrorOrigin.DRIVER_ADMINISTRATION));
        dispatch(fetchDriversFailed());
        return Promise.resolve();
    } else {
        return api
            .fetchAllDrivers()
            .then((response: Driver[]) => {
                dispatch(driversFetched(response));

                if (response && response.length >= 1) {
                    // It might be cleaner to use the account ID from the token instead of one in the response (and thus get rid of the guarding `if` clause).
                    // However, this requires to pass the account ID from the state.
                    //
                    // The cleanest solution might be to separate the `fetchDrivers` and `fetchTags` API calls and call both (in parallel) in a single thunk
                    // action `fetchDriversWithTags`.
                    // This is particularly important once we allow the creation of drivers by a fleet admin in the frontend as the fleet admin might want to
                    // add tags as directly upon creation of the driver.
                    // For the time being, however, this is not possible and the above `if` allows us to save an unneeded API call in case of no drivers in an account.
                    api.fetchTags({
                        accountId: response[0].accountId,
                        tagsQuery: '',
                    })
                        .then((tag: Tag[]) => {
                            dispatch(tagsFetched(tag));
                        })
                        .catch((errorResponse: ErrorResponse) => {
                            showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.TAG_SERVICE));
                        });
                }
            })
            .catch((errorResponse: ErrorResponse) => {
                showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
                dispatch(fetchDriversFailed());
            });
    }
};

export const fetchDriver = (driverId: string) => (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(fetchDriverRequested());
    return api
        .fetchDriver({ driverId })
        .then((response: Driver) => {
            dispatch(selectedDriverFetched(response));
            return response;
        })
        .catch(() => {
            dispatch(fetchSelectedDriverFailed());
        });
};

export const fetchUsers = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const account = getUserAccount(getState());
    if (isEmpty(account)) {
        const error = {
            status: 400,
            detail: 'No account id provided.',
        };
        showErrorNotification(
            `intl-msg:error.fetch.message.${getErrorCode(error, ErrorOrigin.USER_ADMINISTRATION)}`,
            true
        );
        return Promise.resolve();
    }
    return api
        .fetchAllUsersInAccount()
        .then((response: User[]) => {
            dispatch(usersFetched(response));
        })
        .catch(() => {
            showErrorNotification('intl-msg:error.fetch.users.message', true);
        });
};

export const fetchAllDriversAndPreselectOne = (driverId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    await dispatch(fetchDrivers());
    dispatch(driverSelected(driverId));
};
