import { FormattedMessage } from 'react-intl';
import { formatUserForDisplay, isActiveDriver } from '../../../../../utils';
import type {
    AssignUserFormPropertiesFromDispatch,
    AssignUserFormPropertiesFromState,
} from '../containers/AssignUserFormContainer';

type AssignUserFormProperties = AssignUserFormPropertiesFromState & AssignUserFormPropertiesFromDispatch;

export const AssignUserForm = (props: AssignUserFormProperties) => {
    const { selectedDriver, onUserRemoved, users, openCreateUserDialog } = props;

    const userPresent = selectedDriver ? selectedDriver.subject : false;
    const assignedUser = selectedDriver ? users.find(user => user.subject === selectedDriver.subject) : null;
    const isActive = isActiveDriver(selectedDriver);
    const displayUser = isActive || userPresent;

    return (
        <div>
            {!displayUser ? (
                <div />
            ) : (
                <div>
                    {!userPresent && isActive ? (
                        <div className={'text-center'}>
                            <div className={'text-color-gray  margin-bottom-15'}>
                                <div>
                                    <FormattedMessage id={'intl-msg:createUserNote'} />
                                </div>
                            </div>
                            <div data-testid={'createUserWizardButton'}>
                                <button
                                    type={'button'}
                                    className={'btn btn-link btn-link-inline'}
                                    onClick={openCreateUserDialog}
                                >
                                    <span className={'rioglyph rioglyph-user margin-right-0'} />
                                    <FormattedMessage id={'intl-msg:user'} />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={'form-group'}>
                            <label className={'control-label'}>
                                <FormattedMessage id={'intl-msg:assignedUser'} />
                            </label>
                            <ul className={'list-group'}>
                                <li className={'list-group-item padding-top-0 padding-bottom-0'}>
                                    <div>
                                        <span className={'line-height-btn-sm'}>
                                            <span>{formatUserForDisplay(assignedUser)}</span>
                                        </span>
                                        <div className={'btn-toolbar pull-right'}>
                                            <div className={'btn-group'}>
                                                <button
                                                    className={'btn btn-link btn-muted btn-sm btn-icon-only'}
                                                    data-key={'7bc1d45d-1fcc-4d79-8621-8d30f6a82863'}
                                                    type={'button'}
                                                    onClick={onUserRemoved}
                                                >
                                                    <span
                                                        className={'rioglyph rioglyph-remove'}
                                                        data-key={'7bc1d45d-1fcc-4d79-8621-8d30f6a82863'}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
