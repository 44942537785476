import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    cancelSidebar,
    isChangedRtk,
    setHasChanges,
    setSelectedDriver,
    driverDeselected as driverDeselectedInSidebar,
} from './app/drivers/sidebar/sidebarSlice';
import { driverDeselected, driverSelected, getDriversRtk } from './app/appSlice';
import type { Driver, Tag, TagToCreate } from '../types';
import {
    clearAllTags,
    setTagsToAdd,
    setTagsToCreate,
    setTagsToRemove,
} from './app/drivers/sidebar/tabs/groups/groupSlice';

const extractDriverById = (driverId: string, list: Driver[] = []) =>
    list.find(element => element.driverId === driverId);

export const driverSelectedThunk = createAsyncThunk('app/driverSelectedThunk', (selectedDriverId: string, thunkApi) => {
    const state: any = thunkApi.getState();

    thunkApi.dispatch(driverSelected(selectedDriverId));

    const selectedDriver = extractDriverById(selectedDriverId, getDriversRtk(state));
    thunkApi.dispatch(setSelectedDriver(selectedDriver ? selectedDriver : null));

    return Promise.resolve();
});
export const cancelSidebarThunk = createAsyncThunk('sidebar/cancelSidebarThunk', (_, thunkApi) => {
    const state: any = thunkApi.getState();
    if (isChangedRtk(state)) {
        thunkApi.dispatch(driverDeselected());
    }

    thunkApi.dispatch(cancelSidebar());

    return Promise.resolve();
});

export const setTagsToRemoveThunk = createAsyncThunk('tags/setTagsToRemoveThunk', (tags: Tag[], thunkApi) => {
    thunkApi.dispatch(setTagsToRemove(tags));
    const state: any = thunkApi.getState();
    const hasChanges = state.groups.tagsToAdd.length > 0 || state.groups.tagsToCreate.length > 0 || tags.length > 0;
    thunkApi.dispatch(setHasChanges(hasChanges));
});

export const setTagsThunk = createAsyncThunk(
    'tags/setTagsThunk',
    (tags: { tagsToCreate: TagToCreate[]; tagsToAdd: Tag[]; tagsToRemove: Tag[] }, thunkApi) => {
        // const state: any = thunkApi.getState();
        thunkApi.dispatch(setTagsToCreate(tags.tagsToCreate));
        thunkApi.dispatch(setTagsToAdd(tags.tagsToAdd));
        thunkApi.dispatch(setTagsToRemove(tags.tagsToRemove));

        const hasChanges = tags.tagsToCreate.length > 0 || tags.tagsToAdd.length > 0 || tags.tagsToRemove.length > 0;
        thunkApi.dispatch(setHasChanges(hasChanges));
    }
);

export const deselectDriver = createAsyncThunk('app/deselectDriver', (_, thunkAPI) => {
    thunkAPI.dispatch(driverDeselected());
    thunkAPI.dispatch(driverDeselectedInSidebar());
    thunkAPI.dispatch(clearAllTags());
});
