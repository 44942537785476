import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { isEmpty, propOr, values } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { type Driver, DriverStatus, SidebarContentId } from '../../../../../../types';
import { DeleteDriverDialog } from '../../../../dialogs/components/DeleteDriverDialog';
import { DeleteIdentificationsConfirmDialog } from '../../../../dialogs/components/DeleteIdentificationsConfirmDialog';
import { DiscardDialog } from '../../../../dialogs/components/DiscardDialog';
import { PROP_FIRST_NAME, PROP_LAST_NAME } from '../../../../schema';
import { formatWithSpaces, SIDEBAR_BREAKPOINT_FLY } from '../../../../utils';
import { Footer } from '../../footer/Footer';
import { DriverGroupsFormContainer } from '../../tabs/groups/containers/DriverGroupsFormContainer';
import { DriverInfoFormContainer } from '../../tabs/info/containers/DriverInfoFormContainer';
import { AssignUserFormContainer } from '../../tabs/user/containers/AssignUserFormContainer';
import type { SidebarPropertiesFromDispatch, SidebarPropertiesFromState } from '../containers/SidebarContainer';

const SIDEBAR_DEFAULT_WIDTH = 400;

const hasErrors = (formErrors: { [p: string]: string | undefined }) => !isEmpty(values(formErrors).filter(Boolean));

type SidebarProps = SidebarPropertiesFromState & SidebarPropertiesFromDispatch;

export const DriverSidebar = (props: SidebarProps) => {
    const {
        hasChanges,
        formErrors,
        selectedDriver,
        selectedDriverWithoutEdits,
        showUnsavedChangesDialog,
        contentId,
        tagsToCreate,
        tagsToRemove,
        users,
        tagsToAdd,
        deleteDriver,
        handleKeepEditing,
        saveSelectedDriver,
        toggleDeleteUserDialog,
        setUsersToDelete,
    } = props;
    const [showDeleteDriverDialog, setShowDeleteDriverDialog] = useState<boolean>(false);
    const [showDeleteIdentificationsDialog, setShowDeleteIdentificationsDialog] = useState<boolean>(false);

    const handleTabChange = (event: React.MouseEvent) => {
        event.preventDefault();
        const clickedContentId = event.currentTarget.getAttribute('data-contentid');
        if (clickedContentId === SidebarContentId.INFO) {
            props.handleTabChange(SidebarContentId.INFO);
        } else if (clickedContentId === SidebarContentId.USER) {
            props.handleTabChange(SidebarContentId.USER);
        } else if (clickedContentId === SidebarContentId.GROUP) {
            props.handleTabChange(SidebarContentId.GROUP);
        }
    };

    const handleConfirmDiscardAndChangeTabIfRequired = () => {
        if (props.nextTab !== null) {
            props.confirmDiscardAndChangeTab();
        } else {
            props.handleResetSidebar();
        }
    };
    const updateDriver = () => {
        if (selectedDriver !== null) {
            saveSelectedDriver(
                trimDriver(selectedDriver),
                tagsToCreate,
                tagsToAdd,
                tagsToRemove,
                props.newIdentification,
                props.identificationIdsPendingForDeletion
            );
            if (statusChangedToArchived()) {
                if (selectedDriver.subject !== undefined) {
                    const usersToDelete = users.filter(user => user.subject === selectedDriver.subject);
                    setUsersToDelete(usersToDelete);
                    toggleDeleteUserDialog(true);
                }
            }
        }
    };

    const handleSave = () => {
        if (props.identificationIdsPendingForDeletion.size > 0) {
            setShowDeleteIdentificationsDialog(true);
        } else {
            updateDriver();
        }
    };
    const handleCancelDeleteIdentifications = () => {
        setShowDeleteIdentificationsDialog(false);
    };
    const handleConfirmDeleteIdentifications = () => {
        setShowDeleteIdentificationsDialog(false);
        updateDriver();
    };

    const statusChangedToArchived = () => {
        return (
            selectedDriver?.status === DriverStatus.ARCHIVED &&
            selectedDriverWithoutEdits?.status !== DriverStatus.ARCHIVED
        );
    };

    const trimDriver = (driver: Driver): Driver => ({
        ...driver,
        firstName: driver.firstName?.trim(),
        lastName: driver.lastName?.trim(),
    });

    const handleDelete = () => {
        if (selectedDriver?.subject !== undefined) {
            const usersToDelete = users.filter(user => user.subject === selectedDriver.subject);
            setUsersToDelete(usersToDelete);
        }
        setShowDeleteDriverDialog(true);
    };

    const handleAbortDriverDeletion = () => {
        setUsersToDelete([]);
        setShowDeleteDriverDialog(false);
    };

    const handleConfirmDeleteDriver = () => {
        if (selectedDriver !== null) {
            if (selectedDriver?.subject) {
                toggleDeleteUserDialog(true);
            }
            deleteDriver(selectedDriver);
        }
        setShowDeleteDriverDialog(false);
    };

    const renderTabs = () => {
        return (
            <ul className='nav nav-pills nav-justified'>
                <li data-testid={'sidebarTabInfo'} className={`${props.contentId === 'info' ? 'active' : ''}`}>
                    {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
                    <a data-contentid={'info'} onClick={props.contentId === 'info' ? () => {} : handleTabChange}>
                        <FormattedMessage id={'intl-msg:sidebarDriverInfo'} />
                    </a>
                </li>
                <li data-testid={'sidebarTabGroup'} className={`${props.contentId === 'group' ? 'active' : ''}`}>
                    {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
                    <a data-contentid={'group'} onClick={props.contentId === 'group' ? () => {} : handleTabChange}>
                        <FormattedMessage id={'intl-msg:sidebarDriverGroups'} />
                    </a>
                </li>
                <li data-testid={'sidebarTabUser'} className={`${props.contentId === 'user' ? 'active' : ''}`}>
                    {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
                    <a data-contentid={'user'} onClick={props.contentId === 'user' ? () => {} : handleTabChange}>
                        <FormattedMessage id={'intl-msg:sidebarDriverUser'} />
                    </a>
                </li>
            </ul>
        );
    };

    const renderTab = (tabName: string) => {
        switch (tabName) {
            case 'user':
                return <AssignUserFormContainer />;
            case 'group':
                return <DriverGroupsFormContainer />;
            // biome-ignore lint/complexity/noUselessSwitchCase: <explanation>
            case 'info':
            default:
                return <DriverInfoFormContainer />;
        }
    };

    const isSaveButtonEnabled = hasChanges && !hasErrors(formErrors) && !props.inviteUserRequested;
    // As lastName is also the driver card and may contain multiple spaces
    const lastName = formatWithSpaces(propOr('', PROP_LAST_NAME)(selectedDriver));
    const title = `${propOr('', PROP_FIRST_NAME)(selectedDriver)} ${lastName}`;

    const mapIdentificationIdToValue = (identificationId: string): string => {
        return selectedDriver?.identifications?.find(identification => identification.id === identificationId)
            ?.value as string;
    };
    const identificationsToBeDeleted = Array.from(props.identificationIdsPendingForDeletion).map(identificationIds =>
        mapIdentificationIdToValue(identificationIds)
    );

    return (
        <React.Fragment>
            {showUnsavedChangesDialog && (
                <DiscardDialog
                    onConfirmDiscard={handleConfirmDiscardAndChangeTabIfRequired}
                    onKeepEditing={handleKeepEditing}
                />
            )}
            {showDeleteDriverDialog && (
                <DeleteDriverDialog
                    driver={selectedDriver}
                    onConfirmDeleteDriver={handleConfirmDeleteDriver}
                    onCancelDeleteDriver={handleAbortDriverDeletion}
                />
            )}
            <DeleteIdentificationsConfirmDialog
                show={showDeleteIdentificationsDialog}
                identificationsToBeDeleted={identificationsToBeDeleted}
                handleConfirmDeleteIdentifications={handleConfirmDeleteIdentifications}
                handleCancelDeleteIdentifications={handleCancelDeleteIdentifications}
                tenant={props.tenant}
            />
            <Sidebar
                title={title}
                closed={false}
                position={'right'}
                onClose={props.handleCancel}
                footer={
                    <Footer
                        isSaveButtonEnabled={isSaveButtonEnabled}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        data-testid='sidebarFooter'
                    />
                }
                resizable={true}
                width={SIDEBAR_DEFAULT_WIDTH}
                switchModeBreakpoint={SIDEBAR_BREAKPOINT_FLY}
                titleClassName={'padding-left-10'}
                bodyClassName={'padding-20'}
                hasBackdrop={hasChanges}
                onBackdropClick={props.handleCancel}
            >
                {renderTabs()}
                <div className={'padding-top-15'}>{renderTab(contentId)}</div>
            </Sidebar>
        </React.Fragment>
    );
};
