import { connect } from 'react-redux';
import { createUserToggleDialog } from '../userSlice';
import { AssignUserForm } from '../components/AssignUserForm';
import type { Driver, User } from '../../../../../../../types';
import type { RemoveUserAction } from '../../types/sidebarTypes';
import { getSelectedDriverRtk, removeUser } from '../../../sidebarSlice';
import { getUsersRtk } from '../../../../../appSlice';

export interface AssignUserFormPropertiesFromState {
    selectedDriver: Driver | null;
    users: User[];
}
export interface AssignUserFormPropertiesFromDispatch {
    openCreateUserDialog: () => void;
    onUserRemoved: () => RemoveUserAction;
}

const mapStateToProps = (state: any): AssignUserFormPropertiesFromState => ({
    selectedDriver: getSelectedDriverRtk(state),
    users: getUsersRtk(state),
});

const mapDispatchToProps = (dispatch: any): AssignUserFormPropertiesFromDispatch => ({
    openCreateUserDialog: () => dispatch(createUserToggleDialog()),
    onUserRemoved: () => dispatch(removeUser()),
});

export const AssignUserFormContainer = connect(mapStateToProps, mapDispatchToProps)(AssignUserForm);
