import { identity, path } from 'ramda';
import { v4 as uuid } from 'uuid';
import { config } from '../config';
import {
    allIdentificationTypes,
    type ApiCreateTag,
    type ApiDriver,
    type ApiFetchDriver,
    type ApiFetchTag,
    type ApiIdentification,
    type ApiTag,
    type ApiUntag,
    type ApiUser,
    type ApiUserGroup,
    type ApiUserInvite,
    type Driver,
    type Fetch,
    type Identification,
    parseDriverStatus,
    parseIdentificationType,
    type ResponseType,
    type Tag,
    type User,
    type UserGroup,
    type UserWithGroupIds,
} from '../types';
import { filterNonEmpty, jsonOrReject, rejectFailed } from './apiUtils';
import { sleep } from './app/utils';
import { authorizeFetch } from './fetch';
import type { StorageUtil } from '../configuration/tokenHandling/accessToken.ts';

const DRIVERS_ENDPOINT = '/drivers';
const IDENTIFICATIONS_ENDPOINT = '/identifications';
const EU_TACHOGRAPH_CARD = 'eu-tachograph-card';
const RIO_DRIVER_IDENTIFICATION = 'rio-driver-identification';
const COUNTRY_SPECIFIC_DRIVER_LICENSE = 'country-specific-driver-license';

export const allDriversEndpoint = (
    fetch: Fetch,
    storage: StorageUtil,
    validation = identity,
    driverAdminUrl?: string
) => {
    const pull = authorizeFetch(storage, fetch);

    return async () => {
        const requestParams: Record<string, string> = {
            embed: '(identifications,tags)',
        };

        const serializedRequestParams = Object.keys(requestParams)
            .map(key => {
                if (key === 'embed') {
                    return `${key}=${requestParams[key]}`;
                } else {
                    return `${key}=${encodeURIComponent(requestParams[key])}`;
                }
            })
            .join('&');

        const apiUrl = `${driverAdminUrl}${DRIVERS_ENDPOINT}?${serializedRequestParams}`;

        let drivers: Driver[] = [];
        let nextLink = apiUrl;

        const handleGetAllDriversResponse = (serverData: ResponseType) => {
            drivers = drivers.concat(validation(mapGetAllDriversResponse(serverData)));
            nextLink = serverData.body._links?.next?.href;
        };

        while (nextLink !== undefined) {
            await pull(nextLink, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(jsonOrReject)
                .then((serverData: ResponseType) => handleGetAllDriversResponse(serverData));
        }
        return drivers;
    };
};

export const driverEndpoint = (fetch: Fetch, storage: StorageUtil, validation = identity, driverAdminUrl?: string) => {
    const pull = authorizeFetch(storage, fetch);

    return (data: ApiFetchDriver | null = null) => {
        const driverId = data ? data.driverId : '';

        return pull(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${driverId}?embed=(identifications,tags)`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(jsonOrReject)
            .then((serverData: ResponseType) => validation(mapGetSingleDriverResponse(serverData)));
    };
};

export const putDriverEndpoint = (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string) => (driver: Driver) => {
    const put = authorizeFetch(storage, fetch);
    const newData = mapModelDriverToApi(driver);
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const driverId = driver.driverId;

    return put(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${driverId}`, {
        body: JSON.stringify(
            {
                ...newData,
            },
            filterNonEmpty
        ),
        headers,
        method: 'PUT',
    }).then(rejectFailed);
};

export const addIdentificationEndpoint =
    (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string) =>
    (driverId: string, identification: Identification) => {
        const put = authorizeFetch(storage, fetch);
        const identificationToCreate = mapModelIdentificationToApi(identification);
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        return put(
            `${driverAdminUrl}${DRIVERS_ENDPOINT}/${driverId}${IDENTIFICATIONS_ENDPOINT}/${identificationToCreate.id}`,
            {
                body: JSON.stringify({
                    ...identificationToCreate,
                }),
                headers,
                method: 'PUT',
            }
        ).then(rejectFailed);
    };

export const deleteIdentificationEndpoint =
    (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string) => (driverId: string, identificationId: string) => {
        const del = authorizeFetch(storage, fetch);
        return del(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${driverId}${IDENTIFICATIONS_ENDPOINT}/${identificationId}`, {
            method: 'DELETE',
        }).then(rejectFailed);
    };

export const deleteDriverEndpoint =
    (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string) => (driver: Driver) => {
        const deleteDriver = authorizeFetch(storage, fetch);

        const driverId = driver.driverId;

        return deleteDriver(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${driverId}`, {
            method: 'DELETE',
        }).then(rejectFailed);
    };

export const allUsersEndpoint = (fetch: Fetch, storage: StorageUtil, newUserAdminUrl?: string) => async () => {
    const pull = authorizeFetch(storage, fetch);

    let users: User[] = [];
    let nextLink = `${newUserAdminUrl}/users?limit=1000`;

    const handleUserResponse = (serverData: ResponseType) => {
        users = users.concat(mapAllUsersResponse(serverData));
        nextLink = serverData.body._links?.next?.href;
    };

    while (nextLink !== undefined) {
        await pull(nextLink, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(jsonOrReject)
            .then((serverData: ResponseType) => handleUserResponse(serverData));
    }

    return users;
};

export const inviteUserEndpoint =
    (fetch: Fetch, storage: StorageUtil, userAdminUrl?: string) => (data: { user: UserWithGroupIds }) => {
        const put = authorizeFetch(storage, fetch);
        const { user } = data;
        const userResource = mapModelUserToApi(user);

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'If-None-Match': '*',
        };

        return put(`${userAdminUrl}/users/${userResource.id}`, {
            body: JSON.stringify(
                {
                    ...userResource,
                },
                filterNonEmpty
            ),
            headers,
            method: 'PUT',
        })
            .then(jsonOrReject)
            .then((serverData: ResponseType) => mapApiUserToModel(serverData.body));
    };

export const getGroupsEndpoint = (fetch: Fetch, storage: StorageUtil, newUserAdminUrl?: string) => () => {
    const pull = authorizeFetch(storage, fetch);

    return pull(`${newUserAdminUrl}/groups?limit=100`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(jsonOrReject)
        .then((serverData: ResponseType) => mapUserGroupResponse(serverData));
};

export const deleteUserEndpoint = (fetch: Fetch, storage: StorageUtil, newUserAdminUrl?: string) => (user: User) => {
    const deleteUser = authorizeFetch(storage, fetch);

    const headers = {
        'Content-Type': 'application/json',
    };

    return deleteUser(`${newUserAdminUrl}/users/${user.id}`, {
        headers,
        method: 'DELETE',
    }).then(rejectFailed);
};

export const fetchTagsEndpoint =
    (fetch: Fetch, storage: StorageUtil, tagsServiceUrl?: string) => async (data: ApiFetchTag) => {
        const pull = authorizeFetch(storage, fetch);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const { accountId, tagsQuery } = data;

        const url = new URL(`${tagsServiceUrl}/tags`);
        url.searchParams.append('account_id', accountId);
        if (tagsQuery) {
            url.searchParams.append('name', tagsQuery);
        }
        let nextLink = url.toString();
        let tags: Tag[] = [];

        while (nextLink !== null && nextLink !== undefined) {
            const apiResponse = await pull(nextLink, headers).then(jsonOrReject);

            nextLink = apiResponse?.body?._links?.next?.href;
            tags = tags.concat(mapTagsResponse(apiResponse));
        }
        return tags;
    };

export const createTagsEndpoint =
    (fetch: Fetch, storage: StorageUtil, tagsServiceUrl?: string) => (data: ApiCreateTag) => {
        const put = authorizeFetch(storage, fetch);
        const newData = mapModelTagToTagApi(data.tagName, data.accountId);
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'If-None-Match': '*',
        };

        return put(`${tagsServiceUrl}/tags/${newData.id}`, {
            body: JSON.stringify(
                {
                    ...newData,
                },
                filterNonEmpty
            ),
            headers,
            method: 'PUT',
        })
            .then(rejectFailed)
            .then(() => newData);
    };

export const tagDriverEndpoint =
    (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string, retry = 0) =>
    (data: ApiUntag) => {
        const put = authorizeFetch(storage, fetch);
        const tag = { id: data.tagId };
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        return put(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${data.driverId}/tags/${data.tagId}`, {
            body: JSON.stringify(
                {
                    ...tag,
                },
                filterNonEmpty
            ),
            headers,
            method: 'PUT',
        })
            .then(rejectFailed)
            .catch(async (response: ResponseType) => {
                if (retry < config.actions.apiEndpoints.maximumTagDriverRetries) {
                    await sleep(config.actions.apiEndpoints.sleepBetweenTagDriverRetriesMs);
                    // biome-ignore lint/style/noParameterAssign: necessary here for retry
                    return await tagDriverEndpoint(fetch, storage, driverAdminUrl, ++retry)(data);
                } else {
                    return Promise.reject(response);
                }
            });
    };

export const untagDriverEndpoint =
    (fetch: Fetch, storage: StorageUtil, driverAdminUrl?: string) => (data: ApiUntag) => {
        const put = authorizeFetch(storage, fetch);
        const headers = {
            Accept: 'application/json',
        };

        return put(`${driverAdminUrl}${DRIVERS_ENDPOINT}/${data.driverId}/tags/${data.tagId}`, {
            headers,
            method: 'DELETE',
        }).then(rejectFailed);
    };

export const getActiveCard = (identifications: ApiIdentification[]) => {
    const latestEuTachographCard = getLatestEuTachographCard(identifications);
    const countrySpecificDriverLicense = getCountrySpecificDriverLicense(identifications);

    if (latestEuTachographCard !== '') {
        return latestEuTachographCard;
    } else if (countrySpecificDriverLicense !== '') {
        return countrySpecificDriverLicense;
    }
    return '';
};

function getCountrySpecificDriverLicense(identifications: ApiIdentification[]) {
    const countrySpecificDriverLicenses = identifications.filter(
        identification => identification.identification_type === COUNTRY_SPECIFIC_DRIVER_LICENSE
    );
    if (!countrySpecificDriverLicenses || countrySpecificDriverLicenses.length === 0) {
        return '';
    }
    return countrySpecificDriverLicenses[0].identification;
}

function getLatestEuTachographCard(identifications: ApiIdentification[]) {
    const tachographCards = identifications.filter(
        identification => identification.identification_type === EU_TACHOGRAPH_CARD
    );

    if (!tachographCards || tachographCards.length === 0) {
        return '';
    }

    return tachographCards.sort((c1, c2) => {
        const lastDigits1 = Number.parseInt(c1.identification.substring(c1.identification.length - 2), 36);
        const lastDigits2 = Number.parseInt(c2.identification.substring(c2.identification.length - 2), 36);
        return lastDigits1 - lastDigits2;
    })[tachographCards.length - 1].identification;
}

export const getOtherCards = (identifications: ApiIdentification[]) => {
    const allCards = identifications.filter(
        identification => identification.identification_type !== RIO_DRIVER_IDENTIFICATION
    );

    if (!allCards || allCards.length <= 1) {
        return [];
    }

    const activeCard = getActiveCard(identifications);

    return allCards.filter(card => card.identification !== activeCard).map(inactiveCard => inactiveCard.identification);
};

export const mapApiDriverToModel = (data: ApiDriver): Driver => {
    const identifications: ApiIdentification[] = path(['_embedded', 'identifications'], data) || [];
    const tags: ApiTag[] = path(['_embedded', 'tags'], data) || [];

    return {
        driverId: data.id,
        accountId: data.account_id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.phone_number,
        status: parseDriverStatus(data.status),
        subject: data.subject,
        tags: tags ? tags.map(tag => tag.id) : [],
        activeCardNumber: getActiveCard(identifications),
        otherCardNumbers: getOtherCards(identifications),
        identifications: identifications
            .filter(i => allIdentificationTypes.includes(i.identification_type))
            .map(i => mapApiIdentificationToModel(i)),
    };
};

export const mapApiIdentificationToModel = (identification: ApiIdentification): Identification => {
    return {
        id: identification.id,
        value: identification.identification,
        type: parseIdentificationType(identification.identification_type),
    };
};

export const mapModelIdentificationToApi = (identification: Identification) => {
    return {
        id: identification.id,
        identification: identification.value,
        identification_type: identification.type,
    };
};

export const mapModelDriverToApi = (data: Driver): ApiDriver => ({
    id: data.driverId,
    account_id: data.accountId,
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber,
    email: data.email,
    subject: data.subject,
    status: data.status,
});

export const mapModelUserToApi = (user: UserWithGroupIds): ApiUser => ({
    id: user.id,
    account_id: user.accountId,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone_number: user.phoneNumber,
    group_ids: user.groupIds,
    preferred_language: user.preferredLanguage,
});

export const mapApiUserToModel = (user: ApiUser) => {
    return {
        id: user.id,
        accountId: user.account_id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
        subject: user.subject,
    };
};

const mapUserInviteToModel = (user: ApiUserInvite): User => {
    return {
        id: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        subject: user.subject,
    };
};

const mapUserToModel = (user: ApiUser): User => ({
    id: user.id,
    subject: user.subject,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phoneNumber: user.phone_number,
});

const mapUserGroupToModel = (userGroup: ApiUserGroup): UserGroup => ({
    id: userGroup.id,
    name: userGroup.name,
});

const mapTagToModel = (tag: ApiTag): Tag => ({
    id: tag.id,
    type: tag.type,
    name: tag.name,
    accountId: tag.account_id,
});

export const mapModelTagToTagApi = (tagName: string, accountId: string): ApiTag => ({
    id: uuid(),
    type: 'user',
    name: tagName,
    account_id: accountId,
});

export const mapGetSingleDriverResponse = (response: ResponseType) => mapApiDriverToModel(response.body);

export const mapGetAllDriversResponse = (response: ResponseType) => {
    return response.body.items ? response.body.items.map(mapApiDriverToModel) : [];
};

export const mapInviteUserResponse = (response: ResponseType) => mapUserInviteToModel(response.body);

export const mapAllUsersResponse = (response: ResponseType): User[] => {
    return response.body.items ? response.body.items.map((user: ApiUser) => mapUserToModel(user)) : [];
};

export const mapUserGroupResponse = (response: ResponseType) =>
    response.body.items.map((userGroup: ApiUserGroup) => mapUserGroupToModel(userGroup));

export const mapTagsResponse = (response: ResponseType) => response.body.items.map((tag: ApiTag) => mapTagToModel(tag));

export const getEndpoints = () => {
    return {
        fetchAllDriversEndpoint: allDriversEndpoint,
        fetchDriverEndpoint: driverEndpoint,
        saveDriverEndpoint: putDriverEndpoint,
        addIdentificationEndpoint,
        deleteIdentificationEndpoint,
        deleteDriverEndpoint,
        fetchAllUsersEndpoint: allUsersEndpoint,
        inviteUserEndpoint,
        getGroupsEndpoint,
        deleteUserEndpoint,
        fetchTagsEndpoint,
        createTagsEndpoint,
        tagDriverEndpoint,
        untagDriverEndpoint,
    };
};
