import { connect } from 'react-redux';
import { DriverGroupsForm } from '../components/DriverGroupsForm';
import { injectIntl } from 'react-intl';
import type { Tag, TagToCreate } from '../../../../../../../types';
import type {
    DriverGroupsFormPropertiesFromDispatch,
    DriverGroupsFormPropertiesFromState,
} from '../../types/driverGroupsFormTypes';
import { getAllTagsInAccount } from '../../../../../../Drivers.selectors';
import { getSelectedDriverRtk } from '../../../sidebarSlice';
import { setTagsThunk } from '../../../../../../crossSliceThunks';
import type { AppDispatch, RootState } from '../../../../../../../configuration/setup/store';

const mapStateToProps = (state: RootState): DriverGroupsFormPropertiesFromState => ({
    allTagsInAccount: getAllTagsInAccount(state),
    assignedTagIds: getSelectedDriverRtk(state as any)?.tags || [],
});

const mapDispatchToProps = (dispatch: AppDispatch): DriverGroupsFormPropertiesFromDispatch => ({
    setTags(tags: { tagsToCreate: TagToCreate[]; tagsToAdd: Tag[]; tagsToRemove: Tag[] }) {
        dispatch(setTagsThunk(tags));
    },
});

export const DriverGroupsFormContainer = injectIntl(connect(mapStateToProps, mapDispatchToProps)(DriverGroupsForm));
