import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type { User } from '../../../../../../../types';
import { UserAutoSuggest } from './UserAutoSuggest';

interface CreateAssignUserStepProperties {
    assignedUser: User | undefined;
    onSuggestionSelected: (user: User) => void;
    onUserRemoved: () => void;
}

export const CreateAssignUserStep = memo((props: CreateAssignUserStepProperties) => {
    const { assignedUser, onSuggestionSelected, onUserRemoved } = props;

    return (
        <div className={'padding-15'}>
            <div className={'padding-15 text-center'}>
                <div className={'text-size-18 line-height-125rel'}>
                    <FormattedMessage id={'intl-msg:createUserDialog:assignExistingUserNote'} />
                </div>
                <div className={'padding-top-15'}>
                    <UserAutoSuggest
                        assignedUser={assignedUser}
                        onSelectionChange={onSuggestionSelected}
                        onUserRemoved={onUserRemoved}
                    />
                </div>
            </div>
        </div>
    );
});
