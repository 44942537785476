import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
    type Driver,
    type DriverChanges,
    DriverStatus,
    type NewIdentification,
    SidebarContentId,
    type SidebarState,
} from '../../../../types';
import {
    driverEmailSchema,
    driverFirstNameSchema,
    driverLastNameSchema,
    driverPhoneNumberSchema,
    PROP_EMAIL,
    PROP_FIRST_NAME,
    PROP_LAST_NAME,
    PROP_PHONE_NUMBER,
} from '../../schema';
import type { RootState } from '../../../../configuration/setup/store';

export const initialSelectedDriverChanges = (): DriverChanges => ({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    status: false,
    user: false,
    pendingIdentificationDeletions: false,
    pendingNewIdentification: false,
});

export const initialState: SidebarState = {
    hasChanges: false,
    formErrors: {},
    selectedDriver: null,
    selectedDriverChanges: initialSelectedDriverChanges(),
    selectedDriverWithoutEdits: null,
    pendingIdentificationDeletions: [],
    newIdentification: null,
    showNotificationCreation: false,
    contentId: SidebarContentId.INFO,
    fetchDriverRequested: false,
    inviteUserRequested: false,
    showUnsavedChangesDialog: false,
    pendingTabChangeNextTab: null,
};

const checkInputFieldForChange = (current: string | undefined, stored: string | undefined): boolean => {
    return current ? current !== stored : !['', undefined].includes(stored);
};

const checkSwitchForChange = (current: any, stored: any): boolean => {
    return current !== stored;
};

const checkDriverFormForChanges = (changes: DriverChanges): boolean => {
    return Object.values(changes).some(change => change === true);
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        // case CHANGE_FIRST_NAME:
        changeFirstName: (state, action: PayloadAction<string>) => {
            const { error } = driverFirstNameSchema.validate(action.payload);
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                firstName: checkInputFieldForChange(action.payload, state.selectedDriverWithoutEdits?.firstName),
            };

            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.firstName = action.payload;
            state.selectedDriverChanges.firstName = checkInputFieldForChange(
                action.payload,
                state.selectedDriverWithoutEdits?.firstName
            );

            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.formErrors[PROP_FIRST_NAME] = error && 'intl-msg:error.firstName.maxLength';
        },
        // case CHANGE_LAST_NAME:
        changeLastName: (state, action: PayloadAction<string>) => {
            const { error } = driverLastNameSchema.validate(action.payload);
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                lastName: checkInputFieldForChange(action.payload, state.selectedDriverWithoutEdits?.lastName),
            };

            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.lastName = action.payload;
            state.selectedDriverChanges.lastName = checkInputFieldForChange(
                action.payload,
                state.selectedDriverWithoutEdits?.lastName
            );

            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.formErrors[PROP_LAST_NAME] = error && 'intl-msg:error.lastName.requiredAndMaxLength';
        },
        // case CHANGE_EMAIL:
        changeEmail: (state, action: PayloadAction<string>) => {
            const { error } = driverEmailSchema.validate(action.payload);
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                email: checkInputFieldForChange(action.payload, state.selectedDriverWithoutEdits?.email),
            };

            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.email = action.payload;
            state.selectedDriverChanges.email = checkInputFieldForChange(
                action.payload,
                state.selectedDriverWithoutEdits?.email
            );

            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.formErrors[PROP_EMAIL] = error && 'intl-msg:error.email.incorrect';
        },
        // case CHANGE_PHONE_NUMBER:
        changePhoneNumber: (state, action: PayloadAction<string>) => {
            const { error } = driverPhoneNumberSchema.validate(action.payload);
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                phoneNumber: checkInputFieldForChange(action.payload, state.selectedDriverWithoutEdits?.phoneNumber),
            };

            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.phoneNumber = action.payload;
            state.selectedDriverChanges.phoneNumber = checkInputFieldForChange(
                action.payload,
                state.selectedDriverWithoutEdits?.phoneNumber
            );

            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.formErrors[PROP_PHONE_NUMBER] = error && 'intl-msg:error.phoneNumber.invalid';
        },
        // case CHANGE_STATUS:
        changeStatus: state => {
            const newStatus =
                state.selectedDriver?.status === DriverStatus.ACTIVE ? DriverStatus.ARCHIVED : DriverStatus.ACTIVE;
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                status: checkSwitchForChange(newStatus, state.selectedDriverWithoutEdits?.status),
            };

            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.status = newStatus;
            state.selectedDriverChanges.status = checkSwitchForChange(
                newStatus,
                state.selectedDriverWithoutEdits?.status
            );
            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
        },
        // case TOGGLE_PENDING_IDENTIFICATION_DELETION:
        togglePendingIdentificationDeletion: (state, action: PayloadAction<string>) => {
            const updatedPendingDeletions = new Set(state.pendingIdentificationDeletions);
            if (updatedPendingDeletions.has(action.payload)) {
                updatedPendingDeletions.delete(action.payload);
            } else {
                updatedPendingDeletions.add(action.payload);
            }
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                pendingIdentificationDeletions: updatedPendingDeletions.size !== 0,
            };

            state.selectedDriverChanges.pendingIdentificationDeletions = updatedPendingDeletions.size !== 0;
            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.pendingIdentificationDeletions = Array.from(updatedPendingDeletions);
        },
        // case CHANGE_NEW_IDENTIFICATION:
        changeNewIdentification: (state, action: PayloadAction<NewIdentification | null>) => {
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                pendingNewIdentification: action.payload !== null && action.payload.value !== null,
            };

            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.newIdentification = action.payload;
            state.selectedDriverChanges.pendingNewIdentification =
                action.payload !== null && action.payload.value !== null;
            state.formErrors.identification =
                action.payload?.value !== null && action.payload?.isValid ? 'invalid identification' : undefined;
        },
        // case SET_SHOW_NOTIFICATION_CREATION:
        setShowNotificationCreation: (state, action: PayloadAction<boolean>) => {
            const updatedSelectedDriverChanges: DriverChanges = {
                ...state.selectedDriverChanges,
                pendingNewIdentification: false,
            };
            state.hasChanges =
                !!state.selectedDriver &&
                !!state.selectedDriverWithoutEdits &&
                checkDriverFormForChanges(updatedSelectedDriverChanges);
            state.selectedDriverChanges.pendingNewIdentification = false;
            state.showNotificationCreation = action.payload;
            state.newIdentification = null;
            state.formErrors.identification = undefined;
        },
        // case REMOVE_USER:
        removeUser: state => {
            state.hasChanges = true;
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.user = undefined;
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            state.selectedDriver!.subject = undefined;
        },
        // case DELETE_USER_SUCCESS:
        deleteUserSuccess: state => {
            // Actual deletion of link to user happens when event replicates in backend, but to avoid confusion to user
            // delete in local state
            if (state.selectedDriver) {
                state.selectedDriver.user = undefined;
                state.selectedDriver.subject = undefined;
            } else {
                state.selectedDriver = null;
            }

            if (state.selectedDriverWithoutEdits) {
                state.selectedDriverWithoutEdits.user = undefined;
                state.selectedDriverWithoutEdits.subject = undefined;
            } else {
                state.selectedDriverWithoutEdits = null;
            }
        },
        // case DELETE_DRIVER_SUCCESS:
        deleteDriverSuccess: () => initialState,
        // case DISCARD_AND_CHANGE_TAB:
        confirmDiscardAndChangeTab: state => {
            state.contentId = state.pendingTabChangeNextTab as SidebarContentId;
            state.selectedDriver = state.selectedDriverWithoutEdits;
            state.selectedDriverChanges = initialSelectedDriverChanges();
            state.pendingIdentificationDeletions = [];
            state.newIdentification = null;
            state.showNotificationCreation = false;
            state.hasChanges = false;
            state.formErrors = {};
            state.showUnsavedChangesDialog = false;
            state.pendingTabChangeNextTab = null;
        },
        // case KEEP_EDITING:
        keepEditing: state => {
            state.showUnsavedChangesDialog = false;
        },
        // case CHANGE_TAB:
        changeTab: (state, action: PayloadAction<SidebarContentId>) => {
            if (state.hasChanges) {
                state.showUnsavedChangesDialog = true;
                state.pendingTabChangeNextTab = action.payload;
            } else {
                state.contentId = action.payload;
                state.showUnsavedChangesDialog = false;
                state.pendingTabChangeNextTab = null;
            }
        },
        // case SAVE_DRIVER_IN_PROGRESS:
        saveDriverInProgress: state => {
            state.showUnsavedChangesDialog = false;
        },
        // case SAVE_DRIVER_FAILED:
        saveDriverFailed: state => state, // do nothing
        // case SAVE_DRIVER_SUCCESS: {
        saveDriverSuccess: (state, action: PayloadAction<Driver>) => {
            state.hasChanges = false;
            state.formErrors = {};
            state.selectedDriver = action.payload;
            state.selectedDriverChanges = initialSelectedDriverChanges();
            state.newIdentification = null;
            state.showNotificationCreation = false;
            state.pendingIdentificationDeletions = [];
            state.contentId = SidebarContentId.INFO;
        },
        // case DRIVER_DESELECTED:
        driverDeselected: state => {
            state.selectedDriver = null;
        },
        // case DRIVER_FETCH_REQUESTED:
        fetchDriverRequested: state => {
            state.fetchDriverRequested = true;
        },
        // case SELECTED_DRIVER_FETCHED:
        selectedDriverFetched: (state, action: PayloadAction<Driver>) => {
            state.fetchDriverRequested = false;
            state.selectedDriver = action.payload;
            state.selectedDriverWithoutEdits = action.payload;
        },
        // case SELECTED_DRIVER_FETCH_FAILED:
        fetchSelectedDriverFailed: state => {
            state.fetchDriverRequested = false;
        },

        setSelectedDriver: (state, action: PayloadAction<Driver | null>) => {
            state.selectedDriver = action.payload;
            state.selectedDriverWithoutEdits = action.payload;
            state.hasChanges = false;
            state.formErrors = {};
        },

        setHasChanges: (state, action: PayloadAction<boolean>) => {
            state.hasChanges = action.payload;
        },

        // ----------------------------------------
        // FROM CROSS SLICE REDUCER
        // ----------------------------------------
        // CANCEL_SIDEBAR
        cancelSidebar: state => {
            if (state.hasChanges) {
                return { ...state, showUnsavedChangesDialog: true };
            } else {
                return initialState;
            }
        },
        // RESET_SIDEBAR
        resetSidebar: () => initialState,
    },
    extraReducers: builder => {
        // DRIVER_SELECTED
        // builder.addCase(driverSelected, (state, action) => {
        //     state.selectedDriver = '';
        //     state.selectedDriverWithoutEdits = '';
        //     state.hasChanges = false;
        //     state.formErrors = {};
        // });
        // builder.addCase(driverDeselectedRtk, (state) => {
        //     state.selectedDriver = null;
        // });
        // SET_TAGS_TO_CREATE
        // SET_TAGS_TO_ADD
        // SET_TAGS_TO_REMOVE
        builder.addDefaultCase(() => {});
    },
});

export const {
    changeFirstName,
    changeLastName,
    changeNewIdentification,
    changeEmail,
    changeStatus,
    changeTab,
    confirmDiscardAndChangeTab,
    keepEditing,
    removeUser,
    saveDriverFailed,
    saveDriverSuccess,
    selectedDriverFetched,
    fetchSelectedDriverFailed,
    deleteUserSuccess,
    driverDeselected,
    fetchDriverRequested,
    changePhoneNumber,
    setShowNotificationCreation,
    togglePendingIdentificationDeletion,
    saveDriverInProgress,
    deleteDriverSuccess,
    cancelSidebar,
    resetSidebar,
    setSelectedDriver,
    setHasChanges,
} = sidebarSlice.actions;

// SELECTORS
export const isChangedRtk = (state: RootState) => state.sidebar.hasChanges;
export const isFetchDriverInProgressRtk = (state: RootState) => state.sidebar.fetchDriverRequested;
export const getContentIdRtk = (state: RootState) => state.sidebar.contentId;
export const getFormErrorsRtk = (state: RootState): { [p: string]: string | undefined } => state.sidebar.formErrors;
export const getSelectedDriverRtk = (state: RootState) => state.sidebar.selectedDriver;
export const getPendingIdentificationDeletionsRtk = (state: RootState) => state.sidebar.pendingIdentificationDeletions;
export const getNewIdentificationValueRtk = (state: RootState) => state.sidebar.newIdentification;
export const getShowIdentificationCreationRtk = (state: RootState) => state.sidebar.showNotificationCreation;
export const getSelectedDriverWithoutEditsRtk = (state: RootState) => state.sidebar.selectedDriverWithoutEdits;
export const getShowUnsavedChangesRtk = (state: RootState) => state.sidebar.showUnsavedChangesDialog;
export const getNextTabRtk = (state: RootState) => state.sidebar.pendingTabChangeNextTab;

export default sidebarSlice.reducer;
