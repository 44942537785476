import { api } from '../../api';
import { showErrorNotification, showSuccessNotification } from '../notifications/notifications';
import { FLEET_DRIVER_GROUP } from '../schema';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { saveDriver } from './saveDriverThunks';
import type { Driver, User, UserError, UserGroup } from '../../../types';
import type { ThunkDispatch } from 'redux-thunk';
import { fetchUsers } from './fetchThunks';
import { inviteUserFailed, inviteUserRequested, inviteUserSuccess } from '../appSlice';

export const inviteUserAndSaveDriver =
    (userToSave: User, selectedDriver: Driver) => (dispatch: ThunkDispatch<any, any, any>) => {
        dispatch(inviteUserRequested());

        return api
            .getGroups()
            .then((response: UserGroup[]) => response.find((group: UserGroup) => group.name === FLEET_DRIVER_GROUP))
            .then((driverGroup: UserGroup) =>
                api.inviteUser({
                    user: {
                        ...userToSave,
                        groupIds: [driverGroup.id],
                    },
                })
            )
            .then(async (response: User) => {
                const modifiedDriver: Driver = {
                    ...selectedDriver,
                    subject: response.subject,
                };
                showSuccessNotification('intl-msg:users.invite.invitationSent');
                dispatch(inviteUserSuccess());
                await dispatch(fetchUsers());

                return dispatch(saveDriver(modifiedDriver));
            })
            .catch((error: UserError) => {
                dispatch(handleInviteFailed(error, userToSave, selectedDriver));
            });
    };

export const handleInviteFailed = (error: UserError, userToSave: User, selectedDriver: Driver) => {
    if (error.status === 401 || error.status === 403) {
        showErrorNotification('intl-msg:error.invite.unauthorized', true);
    } else if (error.status === 409 || error.detail === 'User already exists') {
        showErrorNotification('intl-msg.error.invite.alreadyExists', true);
    } else {
        reportErrorToSentry(
            new Error(
                `Could not invite user ${userToSave.id} for ${selectedDriver.driverId} in account ${
                    selectedDriver.accountId
                } due to following error: ${JSON.stringify(error)}`
            )
        );
        showErrorNotification('intl-msg:users.invite.error.invitationNotSent', true);
    }
    // return inviteUserFailed();
    return inviteUserFailed();
};
