/**
 * Validation of CNH-11 (Carteira Nacional de Habilitação) driver license number
 *   the only spec/reference apart from an LATAM email was https://github.com/Respect/Validation/blob/3dcd859d986f1b586b5539ea19962723ab7352ed/library/Rules/Cnh.php
 *
 * LATAM email "SPEC":
 *
 *     static isCNHValid(String cnh) {
 *       var char1 = cnh[0];
 *       if (cnh.replaceAll( RegExp.escape("D+"), "" ).length != 11 || "%0+11+d".replaceAll('0', char1) == cnh){
 *         return false;
 *       }
 *       var v = 0;
 *       var j = 9;
 *     	 for(int i = 0; i < 9; ++i, --j){
 *         v += int.parse(cnh[i]) * j;
 *       }
 *     	 var dsc = 0;
 *       var vl1 = v % 11;
 *       if (vl1 >= 10) {
 *         vl1 = 0;
 *         dsc = 2;
 *       }
 *       v = 0;
 *       j = 1;
 *     	 for (int i = 0; i < 9; ++i, ++j) {
 *         v += int.parse(cnh[i]) * j;
 *       }
 *     	 var x = v % 11;
 *       var vl2 = (x >= 10) ? 0 : x - dsc;
 *     	 return vl1.toString() + vl2.toString() == cnh.substring(cnh.length - 2);
 *     }
 *
 *
 * @param input
 */
export const cnh11Validation = (input: string): boolean => {
    // only digits allowed
    if (!input.match(/\d{11}/)) {
        return false;
    }
    const parsedNumbers: number[] = input.split('').map(char => Number.parseInt(char, 10));

    if (allElementsEqual(parsedNumbers)) {
        return false;
    }

    const licenseId: number[] = parsedNumbers.slice(0, 9);
    const { calculatedChecksum1, calculatedChecksum2 } = calculateChecksums(licenseId);

    const checksum1 = parsedNumbers[9];
    const checksum2 = parsedNumbers[10];
    return calculatedChecksum1 === checksum1 && calculatedChecksum2 === checksum2;
};

export const calculateChecksums = (licenseId: number[]) => {
    const weights1 = [9, 8, 7, 6, 5, 4, 3, 2, 1];
    const weights2 = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const rawChecksum1: number = weights1.reduce((aggregate, weight, index) => {
        return aggregate + weight * licenseId[index];
    }, 0);
    const rawChecksum2: number = weights2.reduce((aggregate, weight, index) => {
        return aggregate + weight * licenseId[index];
    }, 0);

    const c1 = (rawChecksum1 % 11) % 10;
    const c1Overflow = rawChecksum1 % 11 === 10 ? 2 : 0;
    let c2 = rawChecksum2 % 11 === 10 ? 0 : positiveMod(rawChecksum2 - c1Overflow, 11);
    // This still allows c2 to be equal to 10 (if rawChecksum2===1 and c1Overflow===2)
    // which seems fishy as this can never result in a valid checksum check
    // However, for a lack of better specifications this is kept.
    // The reference implementations
    //  - https://gist.github.com/felipecwb/f32f70bf05bdf1ec5663
    //  - https://github.com/Respect/Validation/blob/master/library/Rules/Cnh.php
    // contain similar issues.

    // update: according to a reported existing license, which failed the validation,
    // it appears as if a checksum resulting in 10 for the second checksum is mapped to 0
    // this is just a quick fix. There potentially is a better solution
    c2 = c2 % 10;
    return { calculatedChecksum1: c1, calculatedChecksum2: c2 };
};

const positiveMod = (value: number, modulo: number): number => {
    return (value + modulo) % modulo;
};

const allElementsEqual = (parsedNumbers: number[]) => {
    return parsedNumbers.every(value => value === parsedNumbers[0]);
};
