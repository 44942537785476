import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import type { Tenant } from '../../../../../configuration/types';
import { driverCardOrLicenseIntlMessage } from '../../../tenantTypes';
import { TableHead } from './TableHead';
import { DriversRow } from './DriversRow';
import {
    INTL_FIELD_EMAIL,
    INTL_FIELD_FIRST_NAME,
    INTL_FIELD_LAST_NAME,
    INTL_FIELD_PHONE,
    INTL_FIELD_STATUS,
    INTL_FIELD_TAG,
    INTL_FIELD_USER,
    listMode,
    PROP_DRIVER_ID,
    SORT_ASC,
    SORT_DESC,
} from '../../../schema';
import { DriverMultiSelectContainer } from '../containers/DriverMultiSelectContainer';
import { type Driver, SortByField, type User } from '../../../../../types';
import type { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';

export interface DriversTableProps {
    drivers: Driver[];
    users: User[];
    selectedDriverId: string | null;
    driversListMode: TableViewTogglesViewType;
    selectedDriverIdsForMultiSelect: string[];
    toggleAllCheckboxes: (enable: boolean) => void;
    onSelectionChange: (event: React.MouseEvent) => void;
    onSortChange: (event: React.MouseEvent) => void;
    sortBy: string;
    sortDir: string;
    tenant: Tenant | null;
}

export const DriversTable = (props: DriversTableProps) => {
    const intl = useIntl();
    const {
        drivers,
        users,
        selectedDriverId,
        driversListMode,
        selectedDriverIdsForMultiSelect,
        toggleAllCheckboxes,
        onSelectionChange,
        onSortChange,
        sortBy,
        sortDir,
    } = props;

    const tableClassNames = classNames(
        'table',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        'table-column-overflow-hidden',
        listMode.isSplit(driversListMode) && 'table-cards table-multi-cards',
        listMode.isList(driversListMode) && 'table-cards table-single-card'
    );

    return (
        <table className={tableClassNames}>
            <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
            </colgroup>
            <thead>
                <tr>
                    <th className='table-checkbox'>
                        <DriverMultiSelectContainer
                            selectedDriverIds={selectedDriverIdsForMultiSelect}
                            toggleAllCheckboxes={toggleAllCheckboxes}
                        />
                    </th>
                    <TableHead
                        field={SortByField.FIELD_STATUS}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_STATUS}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_STATUS, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_STATUS} />}
                    />
                    <TableHead
                        field={SortByField.FIELD_FIRST_NAME}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_FIRST_NAME}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_FIRST_NAME, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_FIRST_NAME} />}
                    />
                    <TableHead
                        field={SortByField.FIELD_LAST_NAME}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_LAST_NAME}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_LAST_NAME, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_LAST_NAME} />}
                    />
                    <TableHead
                        field={SortByField.FIELD_CARD}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_CARD}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_CARD, sortBy)}
                        text={driverCardOrLicenseIntlMessage(props.tenant, intl)}
                    />
                    <TableHead
                        field={SortByField.FIELD_EMAIL}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_EMAIL}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_EMAIL, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_EMAIL} />}
                    />
                    <TableHead
                        field={SortByField.FIELD_PHONE}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_PHONE}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_PHONE, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_PHONE} />}
                    />
                    <TableHead
                        field={SortByField.FIELD_USER}
                        onClick={onSortChange}
                        sortBy={SortByField.FIELD_USER}
                        sortDir={getSortOrder(sortDir, SortByField.FIELD_USER, sortBy)}
                        text={<FormattedMessage id={INTL_FIELD_USER} />}
                    />
                    <TableHead field={SortByField.FIELD_TAG} text={<FormattedMessage id={INTL_FIELD_TAG} />} />
                </tr>
            </thead>
            <tbody data-testid={'driverTableBody'}>
                {drivers.map(driver => {
                    return (
                        <DriversRow
                            key={driver[PROP_DRIVER_ID]}
                            driver={driver}
                            selectedDriverIdsForMultiSelect={selectedDriverIdsForMultiSelect}
                            onSelectionChange={onSelectionChange}
                            rowActive={selectedDriverId === driver.driverId}
                            user={users.find(user => user.subject === driver.subject)}
                        />
                    );
                })}
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
                <tr className={'table-card-placeholder'} />
            </tbody>
        </table>
    );
};

export const getSortOrder = (sortDir: string, field: string, sortBy = '') => {
    if (sortBy !== field) {
        return '';
    }
    return sortDir === SORT_ASC ? SORT_DESC : SORT_ASC;
};
