import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

interface CreateUserModeStepProperties {
    onCreateUserClick: () => void;
    onAssignUserClick: () => void;
}

export const CreateUserModeStep = memo((props: CreateUserModeStepProperties) => {
    return (
        <div className={'padding-15'}>
            <div className={'padding-15 text-center'}>
                <div className={'text-size-18 line-height-125rel'}>
                    <FormattedMessage id={'intl-msg:createUserDialog.step1.info'} />
                </div>
            </div>
            <div className={'display-flex flex-wrap justify-content-center'}>
                <a
                    className={'btn btn-primary btn-action text-size-20 margin-10'}
                    data-testid={'createUserBtn'}
                    // biome-ignore lint/a11y/useValidAnchor: <explanation>
                    onClick={props.onCreateUserClick}
                >
                    <span className={'rioglyph rioglyph-plus'} />
                    <FormattedMessage id={'intl-msg:createUser'} />
                </a>
                <a
                    className={'btn btn-primary btn-action text-size-20 margin-10'}
                    data-testid={'assignUserBtn'}
                    // biome-ignore lint/a11y/useValidAnchor: <explanation>
                    onClick={props.onAssignUserClick}
                >
                    <span className={'rioglyph rioglyph-user'} />
                    <FormattedMessage id={'intl-msg:assignUser'} />
                </a>
            </div>
        </div>
    );
});

CreateUserModeStep.displayName = 'CreateUserModeStep';
