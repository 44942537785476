import type { AccessToken } from '../types';

export interface StorageUtil {
    discardAccessToken: () => void;
    getAccessToken: () => string | null;
    hasAccessToken: () => boolean;
    saveAccessToken: (token: AccessToken) => void;
}

export const configureStorage = (): StorageUtil => {
    let storedAccessToken: string | null = null;
    return {
        discardAccessToken: () => {
            storedAccessToken = null;
        },
        getAccessToken: () => storedAccessToken,
        hasAccessToken: () => Boolean(storedAccessToken),
        saveAccessToken: (token: AccessToken) => {
            if (token) {
                storedAccessToken = token;
            } else {
                storedAccessToken = null;
            }
        },
    };
};

export const accessToken = configureStorage();
