import type React from 'react';
import { useEffect, useState } from 'react';
import AutoSuggest, { type AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import classNames from 'classnames';
import { formatUserForDisplay } from '../../../../../utils';
import type { Driver, User } from '../../../../../../../types';
import { useIntl } from 'react-intl';
import type { SelectedSuggestion } from '@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest';
import { useAppSelector } from '../../../../../../../configuration/setup/hooks';
import { getDriversRtk, getUsersRtk } from '../../../../../appSlice';
import { isFetchDriverInProgressRtk } from '../../../sidebarSlice';

interface UserAutoSuggestOwnProperties {
    assignedUser: User | undefined;
    onSelectionChange: (user: User) => void;
    onUserRemoved: () => void;
}

type UserAutoSuggestProperties = UserAutoSuggestOwnProperties;

const userToSuggestion = (user: User) => ({
    label: user.id,
    customSuggestion: `${user.firstName} ${user.lastName} (${user.email ? user.email : user.phoneNumber})`,
});

export const UserAutoSuggest = (props: UserAutoSuggestProperties) => {
    const { assignedUser, onSelectionChange, onUserRemoved } = props;

    const users = useAppSelector(getUsersRtk);
    const drivers = useAppSelector(getDriversRtk);
    const disabled = useAppSelector(isFetchDriverInProgressRtk);

    const [userSuggestions, setUserSuggestions] = useState<AutoSuggestSuggestion[]>([]);
    const [userToDisplay, setUserToDisplay] = useState<string | undefined>(formatUserForDisplay(assignedUser));
    const noItemFoundMessage = '...';

    const intl = useIntl();

    useEffect(() => {
        setUserToDisplay(formatUserForDisplay(assignedUser));
    }, [assignedUser]);

    const getSuggestionValue = (suggestedUser: AutoSuggestSuggestion) => {
        return formatUserForDisplay(suggestedUser);
    };

    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => {
        return <span>{formatUserForDisplay(suggestion)}</span>;
    };

    const onSuggestionSelected = (
        _evt: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        { suggestion }: SelectedSuggestion
    ) => {
        const selectedUser = users.find((u: User) => u.id === suggestion.label);
        if (selectedUser) {
            onSelectionChange(selectedUser);
        }
    };

    const getAssignableUsers = (): User[] => {
        return users.filter(
            (u: User) => !drivers.some((driver: Driver) => driver.subject && driver.subject === u.subject)
        );
    };

    const filterUsersInAccount = (usersToFilter: User[], inputValue: string) => {
        return inputValue
            .toLocaleLowerCase()
            .split(' ')
            .reduce(
                (prevResult, searchString) =>
                    prevResult.filter(
                        (userToFilter: User) =>
                            userToFilter.firstName.toLowerCase().includes(searchString) ||
                            userToFilter.lastName.toLowerCase().includes(searchString) ||
                            userToFilter.email?.toLowerCase().includes(searchString) ||
                            userToFilter.phoneNumber?.includes(searchString)
                    ),
                usersToFilter
            );
    };

    const onSuggestionsFetchRequested = (argument: { value: string }) => {
        const inputValue = argument.value ? argument.value.trim().toLowerCase() : '';
        setUserSuggestions(filterUsersInAccount(getAssignableUsers(), inputValue).map(userToSuggestion));
    };

    const handleChange = (_: any, { newValue }: any) => {
        setUserToDisplay(newValue);
    };

    const handleClear = () => {
        setUserSuggestions(getAssignableUsers().map(userToSuggestion));
        setUserToDisplay('');
        onUserRemoved();
    };

    const inactiveAutoSuggest = (
        <div className={'ClearableInput input-group mock'}>
            <input value={userToDisplay} className={'form-control withClearButton'} readOnly={true} type={'text'} />
            <span onClick={handleClear} className={'clearButton'}>
                <span className={'clearButtonIcon rioglyph rioglyph-remove-sign'} />
            </span>
        </div>
    );

    const inputProperties = {
        onChange: handleChange,
        onClear: handleClear,
        value: userToDisplay,
        disabled,
    };

    return (
        <div className={classNames('form-group')}>
            {assignedUser ? (
                inactiveAutoSuggest
            ) : (
                <AutoSuggest
                    className={'form-group'}
                    dropdownClassName={'bar'}
                    inputProps={{
                        ...inputProperties,
                        placeholder: intl.formatMessage({ id: 'intl-msg:assignExistingUserPlaceholder' }),
                    }}
                    suggestions={userSuggestions}
                    noItemMessage={noItemFoundMessage}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    renderSuggestion={renderSuggestion}
                    getSuggestionValue={getSuggestionValue}
                />
            )}
        </div>
    );
};
