import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInputGroup } from '../../../commonElements/FormInputGroup';
import {
    INTL_FIELD_PHONE,
    INTL_FIELD_FIRST_NAME,
    INTL_FIELD_LAST_NAME,
    PROP_FIRST_NAME,
    PROP_LAST_NAME,
    PROP_PHONE_NUMBER,
} from '../../../../../schema';
import { SortByField, type User } from '../../../../../../../types';

interface CreateNewUserByPhoneStepProperties {
    user: User;
    formErrors: { [key: string]: string | undefined } | undefined;
    handleFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const getMessageById = (id: string | undefined) => id && <FormattedMessage id={id} />;

export const CreateNewUserByPhoneStep = memo((props: CreateNewUserByPhoneStepProperties) => {
    return (
        <div>
            <div className={'modal-body'}>
                <FormInputGroup
                    fieldError={props.formErrors ? getMessageById(props.formErrors[PROP_FIRST_NAME]) : ''}
                    fieldLabel={<FormattedMessage id={INTL_FIELD_FIRST_NAME} />}
                    fieldName={SortByField.FIELD_FIRST_NAME}
                    onChange={props.handleFirstNameChange}
                    readOnly={false}
                    value={props.user.firstName}
                    type={'text'}
                />
                <FormInputGroup
                    fieldError={props.formErrors ? getMessageById(props.formErrors[PROP_LAST_NAME]) : ''}
                    fieldLabel={<FormattedMessage id={INTL_FIELD_LAST_NAME} />}
                    fieldName={SortByField.FIELD_LAST_NAME}
                    onChange={props.handleLastNameChange}
                    readOnly={false}
                    value={props.user.lastName}
                    type={'text'}
                />
                <FormInputGroup
                    fieldError={props.formErrors ? getMessageById(props.formErrors[PROP_PHONE_NUMBER]) : ''}
                    fieldLabel={<FormattedMessage id={INTL_FIELD_PHONE} />}
                    fieldName={SortByField.FIELD_PHONE}
                    onChange={props.handlePhoneChange}
                    readOnly={false}
                    value={props.user.phoneNumber}
                    icon={'rioglyph rioglyph-phone'}
                    type={'text'}
                />
            </div>
        </div>
    );
});

CreateNewUserByPhoneStep.displayName = 'CreateNewUserByPhoneStep';
