import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Button from '@rio-cloud/rio-uikit/Button';

import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, type IntlShape } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
    type Driver,
    DriverStatus,
    type DriverToCreate,
    type IdentificationTypes,
    intlDriverStatus,
    type NewIdentification,
} from '../../../../types';
import { FormInputGroup } from '../../drivers/sidebar/commonElements/FormInputGroup';
import { IdentificationFieldRow } from '../../form/components/IdentificationFieldRow';
import {
    driverEmailSchema,
    driverFirstNameUpdateSchema,
    driverLastNameUpdateSchema,
    driverPhoneNumberSchema,
} from '../../schema';
import type {
    CreateDriverDialogPropsFromDispatch,
    CreateDriverDialogPropsFromState,
} from '../containers/CreateDriverDialogContainer';

export type CreateDriverDialogProps = CreateDriverDialogPropsFromState & CreateDriverDialogPropsFromDispatch;

function scrollToActiveDriverIfExists(retries = 3) {
    if (retries <= 0) {
        return;
    }

    setTimeout(() => {
        const row = document.querySelector('tr.active');
        if (row) {
            // smooth scrolling breaks cypress in electron https://github.com/cypress-io/cypress/issues/3200
            row.scrollIntoView({ block: 'center' });
        } else {
            scrollToActiveDriverIfExists(retries - 1);
        }
    }, 50);
}

export const CreateDriverDialog = (props: CreateDriverDialogProps) => {
    const [firstName, setFirstName] = useState<string | undefined>();
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>();
    const [phone, setPhone] = useState<string | undefined>();

    const [driverIdentificationValid, setDriverIdentificationValid] = useState<boolean>(false);
    const [driverIdentification, setDriverIdentification] = useState<string>('');
    const [driverIdentificationType, setDriverIdentificationType] = useState<IdentificationTypes | undefined>();

    const onSave = () => {
        const driverToCreate: DriverToCreate = {
            driverId: uuidv4(),
            firstName,
            lastName: lastName ?? '',
            accountId: props.accountId as string,
            status: DriverStatus.ACTIVE,
            phoneNumber: phone,
            email,
            identification: {
                id: uuidv4(),
                value: driverIdentification,
                type: driverIdentificationType as IdentificationTypes,
            },
        };
        props.createAndSelectDriver(driverToCreate);
    };

    const invalidFirstName = (): boolean => driverFirstNameUpdateSchema.validate(firstName).error !== undefined;
    const invalidLastName = (): boolean => {
        if (lastName !== undefined) {
            return driverLastNameUpdateSchema.validate(lastName).error !== undefined;
        } else {
            // applies only before first change to input
            return false;
        }
    };
    const invalidEmail = (): boolean => driverEmailSchema.validate(email).error !== undefined;
    const invalidPhone = (): boolean => driverPhoneNumberSchema.validate(phone).error !== undefined;

    const isMandatoryIdentificationMissing = (): boolean => {
        return !driverIdentification;
    };

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value);
    const handleIdentificationChange = useCallback(
        (newIdentification: NewIdentification) => {
            setDriverIdentification(newIdentification.value ? newIdentification.value : '');
            setDriverIdentificationValid(newIdentification.isValid);
            setDriverIdentificationType(newIdentification.type);
        },
        [setDriverIdentification, setDriverIdentificationValid, setDriverIdentificationType]
    );

    useEffect(() => {
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setPhone(undefined);
    }, [props.showDialog]);

    const { driverCreationInProgress } = props;
    useEffect(() => {
        if (!driverCreationInProgress) {
            scrollToActiveDriverIfExists();
        }
    }, [driverCreationInProgress]);

    const nameFormRow = (
        <div className={'row'}>
            <div className='col-12 col-sm-6 form-group'>
                <FormInputGroup
                    fieldError={invalidFirstName() && <FormattedMessage id={'intl-msg:error.firstName.maxLength'} />}
                    fieldLabel={<FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.formLabel.firstName'} />}
                    fieldName={'firstName'}
                    value={firstName || ''}
                    onChange={handleFirstNameChange}
                    readOnly={false}
                    type={'text'}
                    dataAttribute={'createDriverFirstNameField'}
                />
            </div>
            <div className='col-12 col-sm-6 form-group'>
                <FormInputGroup
                    fieldError={
                        invalidLastName() && <FormattedMessage id={'intl-msg:error.lastName.requiredAndMaxLength'} />
                    }
                    fieldLabel={
                        <span>
                            <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.formLabel.lastName'} />*
                        </span>
                    }
                    fieldName={'lastName'}
                    value={lastName || ''}
                    onChange={handleLastNameChange}
                    readOnly={false}
                    type={'text'}
                    dataAttribute={'createDriverLastNameField'}
                />
            </div>
        </div>
    );

    const contactsFormRow = (
        <div className={'row'}>
            <div className='col-12 col-sm-6 form-group'>
                <FormInputGroup
                    fieldError={invalidEmail() && <FormattedMessage id={'intl-msg:error.email.incorrect'} />}
                    fieldLabel={<FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.formLabel.email'} />}
                    fieldName={'email'}
                    value={email || ''}
                    onChange={handleEmailChange}
                    readOnly={false}
                    type={'email'}
                    dataAttribute={'createDriverEmailField'}
                />
            </div>
            <div className='col-12 col-sm-6 form-group'>
                <FormInputGroup
                    fieldError={invalidPhone() && <FormattedMessage id={'intl-msg:error.phoneNumber.invalid'} />}
                    fieldLabel={
                        <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.formLabel.phoneNumber'} />
                    }
                    fieldName={'phone'}
                    value={phone || ''}
                    onChange={handlePhoneChange}
                    readOnly={false}
                    type={'text'}
                    dataAttribute={'createDriverPhoneField'}
                />
            </div>
        </div>
    );

    const form = (
        <>
            {nameFormRow}
            {contactsFormRow}
            <IdentificationFieldRow
                tenant={props.tenant}
                parentDialogShown={props.showDialog}
                existingDrivers={props.existingDrivers}
                onIdentificationChangeCallback={handleIdentificationChange}
                currentDriver={null}
            />
        </>
    );

    const isButtonDisabled = (): boolean => {
        const isAnyFormInputInvalid = invalidEmail() || invalidPhone() || driverIdentificationValid;
        const isMandatoryFieldMissing = !lastName || isMandatoryIdentificationMissing();
        return isAnyFormInputInvalid || isMandatoryFieldMissing || props.driverCreationInProgress;
    };
    const saveButton = (
        <div data-testid={'createDriverDialogSaveButton'}>
            <Button
                className={props.driverCreationInProgress ? 'btn-loading-overlay' : ''}
                bsStyle={'primary'}
                onClick={onSave}
                disabled={isButtonDisabled()}
            >
                <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.button.save'} />
            </Button>
        </div>
    );

    const onHide = () => {
        props.toggleDriverCreationDialog(false);
    };

    return (
        <Dialog
            show={props.showDialog}
            onClose={onHide}
            title={
                <span data-testid={'createDriverDialogTitle'}>
                    <FormattedMessage id={'intl-msg:createDriver.addDriver.dialog.title'} />
                </span>
            }
            footer={saveButton}
            body={form}
            showCloseButton={true}
        />
    );
};

export const existingDriverErrorString = (intl: IntlShape, driver: Driver): string => {
    const intlStatus = intl.formatMessage({
        id: intlDriverStatus(driver.status),
    });
    const fullName =
        driver.firstName || driver.lastName
            ? [driver.firstName, driver.lastName].join(' ')
            : intl.formatMessage({ id: 'intl-msg:noName' });
    return `${fullName} (${intlStatus})`;
};
