import { FormattedMessage } from 'react-intl';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

export const ErrorBlock = () => {
    return (
        <div>
            <div className={'display-flex justify-content-center'}>
                <ErrorState
                    headline={<FormattedMessage id={'intl-msg:error.fetch'} />}
                    message={<FormattedMessage id={'intl-msg:error.fetch.message'} />}
                />
            </div>
        </div>
    );
};
