import Button from '@rio-cloud/rio-uikit/Button';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateDriverDialogContainer } from '../../../dialogs/containers/CreateDriverDialogContainer';
import { DriversTableFilterContainer } from '../containers/DriversTableFilterContainer';
import type { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';

interface DriversTableToolbarProps {
    onViewTypeChange: (mode: TableViewTogglesViewType) => void;
    driversListMode: TableViewTogglesViewType;
    searchText: string;
    onSearchTextChange: (searchText: string) => void;
    showDriverCreationDialog: boolean;
    toggleDriverCreationDialog: (show: boolean) => void;
}

export const DriversTableToolbar = (props: DriversTableToolbarProps) => {
    const searchRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    const intl = useIntl();

    const { onViewTypeChange, driversListMode, searchText, onSearchTextChange } = props;

    const [initialRender, setInitialRender] = useState<boolean>(true);

    // set focus on search field when component renders for the first time
    useEffect(() => {
        if (initialRender) {
            searchRef.current?.focus();
            setInitialRender(false);
        }
    }, [initialRender, setInitialRender, searchRef]);

    useEffect(() => {
        const handleFocusChange = (e: FocusEvent) => {
            console.log('Focus changed to:', e.target);
            console.trace('Focus change stack trace');
        };

        document.addEventListener('focusin', handleFocusChange);
        return () => document.removeEventListener('focusin', handleFocusChange);
    }, []);

    const handleSearchChange = (searchValue: string) => {
        onSearchTextChange(searchValue);
    };

    const handleCreateDriverClick = () => {
        props.toggleDriverCreationDialog(true);
    };

    const createDriverButton = (
        <div data-testid={'createDriverButton'}>
            <Button bsStyle={'primary'} onClick={handleCreateDriverClick}>
                <span className='rioglyph rioglyph-plus' />
                <span>
                    <FormattedMessage id={'intl-msg:createDriver.addDriver.button'} />
                </span>
            </Button>
            <CreateDriverDialogContainer />
        </div>
    );

    return (
        <TableToolbar>
            <div className='table-toolbar-container'>
                <div className='table-toolbar-group-left'>
                    <div className='table-toolbar-column'>{createDriverButton}</div>
                </div>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <DriversTableFilterContainer />
                    </div>
                    <div className='table-toolbar-column'>
                        <div data-testid={'driversTableSearch'} className={'table-toolbar-search input-group'}>
                            <span className={'input-group-addon'}>
                                <span className={'rioglyph rioglyph-search'} />
                            </span>
                            <TableSearch
                                inputRef={searchRef}
                                onChange={handleSearchChange}
                                placeholder={intl.formatMessage({ id: 'intl-msg:searchPlaceholder' })}
                                value={searchText}
                            />
                        </div>
                    </div>
                    <div className='table-toolbar-column'>
                        <TableViewToggles onViewTypeChange={onViewTypeChange} viewType={driversListMode} />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
