import { useEffect, useRef } from 'react';
import { DialogState } from '../../../types';
import { batch } from 'react-redux';
import { deduceDriverStatusesFrom, getDriversUriForDeepLink, parseRoute } from './routeService';
import { useLocation } from 'react-router-dom';
import { useUpdateRoute } from './routeHooks';
import { enumFromEnumValue } from './enumUtils';
import { DriverStatusInRoute } from './types';
import { fetchAllDriversAndPreselectOne } from '../thunks/fetchThunks';
import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from '@reduxjs/toolkit';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import {
    driverStatusesChanged,
    getDriverStatusesRtk,
    getSearchTextRtk,
    getSelectedDriverIdRtk,
    getSortByRtk,
    getSortDirRtk,
    searchTextChanged,
    sortChanged,
    toggleDriverCreationDialog,
} from '../appSlice';
import { changeTab, getContentIdRtk } from '../drivers/sidebar/sidebarSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

type AnyActionOrThunk = AnyAction | ((dispatch: ThunkDispatch<any, any, any>) => Promise<void>);

export const RouteUpdater = (): null => {
    const dispatch = useAppDispatch();

    const currentDriverStatus = useAppSelector(getDriverStatusesRtk);
    const currentSearch = useAppSelector(getSearchTextRtk);
    const currentTab = useAppSelector(getContentIdRtk);
    const routeSearchToPushTo = useAppSelector(getDriversUriForDeepLink);
    const selectedDriverId = useAppSelector(getSelectedDriverIdRtk);
    const selectedSortBy = useAppSelector(getSortByRtk);
    const selectedSortDirString = useAppSelector(getSortDirRtk);

    const selectedSortDir = enumFromEnumValue(SortDirection, selectedSortDirString);
    const { search, pathname } = useLocation();

    // Parse either initial route, or after it has changed by browser navigation, or user input
    useRouteState(() => {
        const dispatchQueue: AnyActionOrThunk[] = [];

        const routeSearchParams = parseRoute({
            location: { search, pathname },
        });

        const {
            driverId,
            sortBy,
            sortDir,
            status,
            search: searchFromRoute,
            tab,
            createDriverModal,
        } = routeSearchParams;
        const driverStatusFromRoute = enumFromEnumValue(DriverStatusInRoute, status);
        const driverStatus = driverStatusFromRoute ? deduceDriverStatusesFrom(driverStatusFromRoute) : undefined;

        if ((sortBy && sortBy !== selectedSortBy) || (sortDir && sortDir !== selectedSortDir)) {
            dispatchQueue.push(
                sortChanged({
                    sortBy: sortBy ? sortBy : selectedSortBy,
                    sortDir: sortDir ? sortDir : selectedSortDir,
                })
            );
        }

        if (searchFromRoute && searchFromRoute !== currentSearch) {
            dispatchQueue.push(searchTextChanged(searchFromRoute));
        }

        if (driverStatus && driverStatus !== currentDriverStatus) {
            dispatchQueue.push(driverStatusesChanged(driverStatus));
        }

        dispatchQueue.push(toggleDriverCreationDialog(createDriverModal === DialogState.SHOW));

        if (tab && tab !== currentTab) {
            dispatchQueue.push(changeTab(tab));
        }

        if (driverId && driverId !== selectedDriverId) {
            dispatchQueue.push(fetchAllDriversAndPreselectOne(driverId));
        }

        batch(() => {
            dispatchQueue.forEach((action: AnyActionOrThunk) => dispatch(action));
        });
    });

    // Update route whenever an observed store prop changes.
    // The key of the RouteState is the search param key in the URL.
    useUpdateRoute(routeSearchToPushTo);

    return null;
};

const useRouteState = (callback: () => void) => {
    const { search } = useLocation();
    const searchRef = useRef('');

    useEffect(() => {
        if (searchRef.current === search) {
            return;
        }
        callback();
        searchRef.current = search;
    }, [search, callback]);
};
