import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DriverStatus } from '../../../../../types';
import { INTL_FIELD_DRIVER_STATUS_ACTIVE, INTL_FIELD_DRIVER_STATUS_ARCHIVED } from '../../../schema';

interface DriverStatusProps {
    driverStatus: DriverStatus;
}

export const DriverStatusHint = (props: DriverStatusProps) => {
    const { driverStatus } = props;

    const statusToolTip = (
        <FormattedMessage
            id={
                driverStatus === DriverStatus.ACTIVE
                    ? INTL_FIELD_DRIVER_STATUS_ACTIVE
                    : INTL_FIELD_DRIVER_STATUS_ARCHIVED
            }
        />
    );

    const labelClassNames = classNames(
        'rioglyph',
        'rioglyph-sphere',
        driverStatus === DriverStatus.ACTIVE && 'text-color-primary',
        driverStatus === DriverStatus.ARCHIVED && 'text-color-light'
    );

    return (
        <OverlayTrigger
            placement={'right'}
            overlay={
                <Tooltip id='tooltip' className='right-top' width='auto'>
                    {statusToolTip}
                </Tooltip>
            }
        >
            <span className={labelClassNames} />
        </OverlayTrigger>
    );
};
