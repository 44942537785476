import { connect } from 'react-redux';
import { Table } from '../components/Table';
import { fetchDrivers, fetchUsers } from '../../../../thunks/fetchThunks';
import { getAllTagsInAccount } from '../../../../../Drivers.selectors';
import type { Driver, DriverStatus, Tag, User } from '../../../../../../types';
import type { TableThunkDispatch } from '../tableTypes';
import type { Tenant } from '../../../../../../configuration/types';
import { selectDriver, sortDrivers } from '../../../../thunks/tableThunks';
import type { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import {
    driversListModeChanged,
    getDriversListModeRtk,
    getDriversRtk,
    getDriverStatusesRtk,
    getSearchTextRtk,
    getSelectedDriverIdRtk,
    getShowDriverCreationDialogRtk,
    getSortByRtk,
    getSortDirRtk,
    getUsersRtk,
    hasFetchDriversRequestedRtk,
    hasFetchErrorRtk,
    searchTextChanged,
    toggleDriverCreationDialog,
} from '../../../../appSlice';
import { getSelectedDriverRtk, isFetchDriverInProgressRtk } from '../../../sidebar/sidebarSlice';
import { deselectDriver } from '../../../../../crossSliceThunks';
import { getTenant, getUserAccount } from '../../../../../../configuration/login/loginSlice';
import type { RootState } from '../../../../../../configuration/setup/store';

export interface TablePropertiesFromState {
    drivers: Driver[];
    users: User[];
    driverStatuses: DriverStatus[];
    driversListMode: TableViewTogglesViewType;
    fetchDriversRequested: boolean;
    fetchDriverRequested: boolean;
    hasDataFetchError: boolean;
    searchText: string;
    selectedDriver: Driver | null;
    selectedDriverId: string | null;
    sortBy: string;
    sortDir: string;
    accountId: string | undefined;
    allTagsInAccount: Tag[];
    showDriverCreationDialog: boolean;
    tenant: Tenant | null;
}

export interface TablePropertiesFromDispatch {
    fetchDrivers: () => Promise<void>;
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    fetchUsers: () => Promise<void | User[]>;
    searchTextChanged: (searchText: string) => void;
    selectionChange: (prevSelectedDriverId: string | undefined, nextSelectedDriverId: string) => void;
    deselectDriver: () => void;
    sortChange: (sortBy: string) => void;
    viewTypeChange: (mode: TableViewTogglesViewType) => void;
    toggleDriverCreationDialog: (show: boolean) => void;
}

const mapStateToProps = (state: RootState): TablePropertiesFromState => ({
    drivers: getDriversRtk(state),
    users: getUsersRtk(state),
    driverStatuses: getDriverStatusesRtk(state),
    driversListMode: getDriversListModeRtk(state),
    fetchDriversRequested: hasFetchDriversRequestedRtk(state),
    fetchDriverRequested: isFetchDriverInProgressRtk(state),
    hasDataFetchError: hasFetchErrorRtk(state),
    searchText: getSearchTextRtk(state),
    selectedDriver: getSelectedDriverRtk(state),
    selectedDriverId: getSelectedDriverIdRtk(state),
    sortBy: getSortByRtk(state),
    sortDir: getSortDirRtk(state),
    accountId: getUserAccount(state),
    allTagsInAccount: getAllTagsInAccount(state),
    showDriverCreationDialog: getShowDriverCreationDialogRtk(state),
    tenant: getTenant(state),
});

const mapDispatchToProps = (dispatch: TableThunkDispatch): TablePropertiesFromDispatch => ({
    fetchDrivers: () => dispatch(fetchDrivers()),
    fetchUsers: () => dispatch(fetchUsers()),
    searchTextChanged: (searchText: string) => dispatch(searchTextChanged(searchText)),
    selectionChange: (prevSelectedDriverId: string | undefined, nextSelectedDriverId: string) =>
        dispatch(selectDriver(prevSelectedDriverId, nextSelectedDriverId)),
    deselectDriver: () => dispatch(deselectDriver()),
    sortChange: (sortBy: string) => dispatch(sortDrivers(sortBy)),
    viewTypeChange: (mode: TableViewTogglesViewType) => dispatch(driversListModeChanged(mode)),
    toggleDriverCreationDialog: (show: boolean) => dispatch(toggleDriverCreationDialog(show)),
});

export const TableContainer = connect(mapStateToProps, mapDispatchToProps)(Table);
