import { type Driver, DriverStatus, type User } from '../../types';
import type { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';

export const LARGE_SCREEN = '1440px';
export const SIDEBAR_BREAKPOINT_FLY = 1440;
export const MOBILE_SCREEN_BREAKPOINT = '768px';

export const isScreenMaxWidth = (breakpoint: any) => window.matchMedia(`(max-width: ${breakpoint})`).matches;
export const isScreenMinWidth = (breakpoint: any) => window.matchMedia(`(min-width: ${breakpoint})`).matches;

export const isLargeScreen = () => isScreenMinWidth(LARGE_SCREEN);

export const isMobile = () => isScreenMaxWidth(MOBILE_SCREEN_BREAKPOINT);

// used to make all spaces in the driver Card visible in the HTML
export const formatWithSpaces = (value = '') => value.replace(/ /gu, '\u00a0');
export const isActiveDriver = (driver: Driver | null) => (driver ? driver.status === DriverStatus.ACTIVE : false);
export const formatUserForDisplay = (user: User | AutoSuggestSuggestion | null | undefined) =>
    user
        ? `${user.firstName} ${user.lastName} (${user.email ? user.email : user.phoneNumber ? user.phoneNumber : ''})`
        : '';
export const formatUserNameForDisplay = (user: User | null | undefined) =>
    user ? `${user.firstName} ${user.lastName}` : '';

export const formatDriverNameForDisplay = (driver: Driver | null | undefined) => {
    if (driver?.firstName && driver.lastName) {
        return `${driver.firstName} ${driver.lastName}`;
    } else if (driver?.lastName) {
        return driver.lastName;
    } else if (driver?.firstName) {
        return driver.firstName;
    } else {
        return '';
    }
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
