import * as React from 'react';
import Tag from '@rio-cloud/rio-uikit/Tag';
import type { DriverTagsInRowPropertiesFromState } from '../containers/DriverTagsInRowContainer';

export type DriverTagsInRowProperties = DriverTagsInRowPropertiesFromState & DriverTagsInRowOwnProperties;

export interface DriverTagsInRowOwnProperties {
    tags: string[];
}

export class DriverTagsInRow extends React.Component<DriverTagsInRowProperties> {
    render() {
        const sortedTags = this.props.allTagsInAccount
            .filter(tag => this.props.tags.includes(tag.id))
            .sort((t1, t2) => t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()));

        return (
            <ul className='list-inline margin-bottom-0'>
                {sortedTags.map(tag => (
                    <li key={tag.id} className='margin-1'>
                        <Tag size={'small'} className='margin-1'>
                            {tag.name}
                        </Tag>
                    </li>
                ))}
            </ul>
        );
    }
}
