import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { main } from './configuration';

import { config } from './config';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import App from './features/app/App';
import { includeGoogleTagManager } from './configuration/setup/analytics';
import { getUserAccount } from './configuration/login/loginSlice';
import { createRoot } from 'react-dom/client';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import type { User } from 'oidc-client-ts';
import { InvalidLogin } from './features/app/invalidLogin';

const redirectUsersUsingOldUrlBookmark = () => {
    window.location.href = window.location.href.replace('/#drivers', '/#/drivers');
};

const renderApplication = (user: User | null) => {
    if (window.location.href.indexOf('/#drivers') > -1) {
        redirectUsersUsingOldUrlBookmark();
    }

    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);

    let showApp = true;
    try {
        showApp = user ? user.profile.sub.startsWith('prod-rio-users:') : true;
    } catch (e) {
        console.error(e);
    }

    includeGoogleTagManager(getUserAccount(store.getState()));

    if (showApp) {
        // Note that we need to use the base "Router" with a "hash" history
        // because the "HashRouter" doesn't allow handing in a history
        // from the outside. So this is effectively a "HashRouter" despite
        // that not being obvious here
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <HashRouter>
                        <App />
                    </HashRouter>
                </Provider>
            </ErrorBoundary>
        );
    } else {
        root.render(<InvalidLogin />);
    }
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    void main(renderApplication);
}
