import { Component, type ErrorInfo, type ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

export class ErrorBoundary extends Component<{ children?: ReactNode }> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (!config.isCapabilityMonitoring) {
            Sentry.withScope(scope => {
                scope.setExtra('componentStack', errorInfo.componentStack);
                Sentry.captureException(error);
            });
        }
    }

    render() {
        return this.props.children;
    }
}
