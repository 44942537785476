import type { CreateDialogStepName, Tag, TagToCreate } from '../types';
import type { RootState } from '../configuration/setup/store';

// =============================
// USER
// =============================
export function getShowDeleteUserDialog(state: RootState) {
    return state.user.userDeletionDialog.showDialog;
}
export function getUsersToDelete(state: RootState) {
    return state.user.userDeletionDialog.usersToDelete;
}
export function getShowUserCreation(state: RootState): boolean {
    return state.user.userDialog.showDialog;
}
export function getUserCreationStep(state: RootState): CreateDialogStepName {
    return state.user.userDialog.currentStep;
}

// =============================
// GROUPS
// =============================
export function getAllTagsInAccount(state: RootState): Tag[] {
    return state.groups.allTagsInAccount;
}
export function getTagsToCreate(state: RootState): TagToCreate[] {
    return state.groups.tagsToCreate;
}
export function getTagsToAdd(state: RootState): Tag[] {
    return state.groups.tagsToAdd;
}
export function getTagsToRemove(state: RootState): Tag[] {
    return state.groups.tagsToRemove;
}
export function getMultiSelectTagsToCreate(state: RootState): TagToCreate[] {
    return state.groups.multiSelect.tagsToCreate;
}
export function getMultiSelectTagsToAdd(state: RootState): Tag[] {
    return state.groups.multiSelect.tagsToAdd;
}
export function getMultiSelectTagsToRemove(state: RootState): Tag[] {
    return state.groups.multiSelect.tagsToRemove;
}
