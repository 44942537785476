import { type Driver, IdentificationTypes } from '../../../types';
import { cnh11Validation } from './cnh11Validation';
import { type DriverLicenseCountry, getCountrySpecificIdentification } from './countrySpecificDriverLicenseCountry';

export const driverWithSameLicenseAlreadyExists = (
    country: DriverLicenseCountry,
    licenseNumber: string,
    existingDriversInAccount: Driver[]
): Driver[] => {
    const countrySpecificIdentification = getCountrySpecificIdentification(country, licenseNumber);
    return existingDriversInAccount.filter(driver =>
        driver.identifications
            .filter(identification => identification.type === IdentificationTypes.COUNTRY_SPECIFIC)
            .some(identification => identification.value === countrySpecificIdentification)
    );
};

export const invalidDriverLicenseNumber = (driverLicenseNumber: string | undefined): boolean =>
    !driverLicenseNumber || !cnh11Validation(driverLicenseNumber);
export const driversWithSameDriverLicense = (
    selectedCountry: DriverLicenseCountry,
    driverCardNumber: string | undefined,
    existingDrivers: Driver[]
): Driver[] =>
    driverCardNumber ? driverWithSameLicenseAlreadyExists(selectedCountry, driverCardNumber, existingDrivers) : [];
