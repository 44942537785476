import type { IParseOptions, IStringifyOptions } from 'qs';

export const STRINGIFY_OPTIONS: IStringifyOptions = {
    arrayFormat: 'comma',
    indices: false,
    encode: true,
    skipNulls: true,
};

export const PARSE_OPTIONS: IParseOptions = {
    comma: true, // required to parse comma separated string into array
};
