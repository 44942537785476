import type { DialogState, Driver, DriverStatus, SidebarContentId } from '../../../types';
import type { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

export interface RouteUpdaterPropertiesFromState {
    sortDir: SortDirectionType;
    sortBy: string;
    searchText: string;
    driverStatuses: Set<DriverStatus>;
    router: any;
    selectedDriver: Driver;
}

export interface RouteUpdaterPropertiesFromDispatch {
    pushRoute: (route: any) => void;
    fetchDrivers: (searchText: string, driverStatuses: DriverStatusInRoute[]) => void;
    fetchDriverById: (driverId: string) => void;
    driverStatusesChanged: (driverStatuses: any) => void;
    searchTextChanged: (searchText: string) => void;
    sortDrivers: (sortBy: string, sortDir: SortDirectionType) => void;
}

export type RouteUpdaterProperties = RouteUpdaterPropertiesFromDispatch & RouteUpdaterPropertiesFromState;

export enum DriverStatusInRoute {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    ALL = 'all',
}

export interface ExtractedPropertiesFromState {
    driverId: string | undefined;
    search: string | undefined;
    sortBy: string | undefined;
    sortDir: SortDirectionType | undefined;
    statuses: Set<DriverStatus>;
}

export interface RouteState {
    driverId: string | undefined;
    search: string | undefined;
    status: DriverStatusInRoute | undefined;
    sortBy: string | undefined;
    sortDir: SortDirectionType | undefined;
    tab: SidebarContentId | undefined;
    createDriverModal: DialogState | undefined;
}

export interface LocationChangePayload {
    location: {
        pathname: string;
        search: string;
    };
}
