import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        APP_REGISTRY: string | undefined;
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        DRIVERADMIN_SERVICE: string | undefined;
        USERADMIN_SERVICE: string | undefined;
        USERADMIN_WEB: string | undefined;
        TAGS_SERVICE: string | undefined;
    };
    links: {
        ASSET_ADMINISTRATION_WEB: string | undefined;
        TAG_ADMINISTRATION_WEB: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
        postLogoutRedirectUri: string | undefined;
    };
    actions: {
        apiEndpoints: {
            maximumTagDriverRetries: number;
            sleepBetweenTagDriverRetriesMs: number;
        };
    };
    serviceVersion: string;
    serviceEnvironment: string;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    isCapabilityMonitoring: boolean;
    enableMockServer: boolean;
}

const asBool = (value: string | undefined): boolean => value === 'true';

const getNumberEnvValue = (envValue: string | undefined, defaultValue: number): number => {
    return typeof envValue === 'string' ? Number.parseInt(envValue, 10) : defaultValue;
};

export const config: ConfigState = {
    backend: {
        APP_REGISTRY: import.meta.env.VITE_APP_REGISTRY,
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        DRIVERADMIN_SERVICE: import.meta.env.VITE_DRIVERADMIN_SERVICE,
        USERADMIN_SERVICE: import.meta.env.VITE_USERADMIN_SERVICE,
        USERADMIN_WEB: import.meta.env.VITE_USERADMIN_WEB,
        TAGS_SERVICE: import.meta.env.VITE_TAGS_SERVICE,
    },
    links: {
        ASSET_ADMINISTRATION_WEB: import.meta.env.VITE_ASSET_ADMINISTRATION_WEB,
        TAG_ADMINISTRATION_WEB: import.meta.env.VITE_TAG_ADMINISTRATION_WEB,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: 'c22cabee-9cd0-4ec6-8e86-ca005dad375d',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'marketplace.read',
            'drivers.read',
            'drivers.write',
            'tags.read',
            'tags.write',
            'iam.user.read',
            'iam.user.write',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: 'rio-eu.test',
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        postLogoutRedirectUri: import.meta.env.VITE_LOGIN_POST_LOGOUT_REDIRECT_URI,
    },
    actions: {
        apiEndpoints: {
            maximumTagDriverRetries: getNumberEnvValue(import.meta.env.VITE_MAXIMUM_TAG_DRIVER_RETRIES, 1),
            sleepBetweenTagDriverRetriesMs: getNumberEnvValue(
                import.meta.env.VITE_SLEEP_BETWEEN_TAG_DRIVER_RETRIES_MS,
                1
            ),
        },
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_TOKEN,
    isCapabilityMonitoring: /cypress/i.test(window.navigator.userAgent),
    enableMockServer: import.meta.env.DEV,
};
