import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import type { Driver } from '../../../../types';
import { formatDriverNameForDisplay } from '../../utils';

interface DeleteDriverDialogProps {
    driver: Driver | null;
    onConfirmDeleteDriver: () => void;
    onCancelDeleteDriver: () => void;
}

export const DeleteDriverDialog = (props: DeleteDriverDialogProps) => {
    const { driver, onConfirmDeleteDriver, onCancelDeleteDriver } = props;

    return (
        <ConfirmationDialog
            show={true}
            bsSize={'sm'}
            useOverflow={true}
            title={<FormattedMessage id={'intl-msg:dialog.deleteDriver.title'} />}
            content={
                <FormattedMessage
                    id={'intl-msg:dialog.deleteDriver.content'}
                    values={{ driverName: formatDriverNameForDisplay(driver) }}
                />
            }
            onClickConfirm={onConfirmDeleteDriver}
            onClickCancel={onCancelDeleteDriver}
            cancelButtonText={
                <div data-testid={'cancelDeleteDriver'}>
                    <FormattedMessage id={'intl-msg:abort'} />
                </div>
            }
            confirmButtonText={
                <div data-testid={'confirmDeleteDriver'}>
                    <FormattedMessage id={'intl-msg:delete'} />
                </div>
            }
        />
    );
};
