import type {
    CreateUserDialogPropertiesFromDispatch,
    CreateUserDialogPropertiesFromState,
} from '../containers/CreateUserDialogContainer';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import type React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CreateUserModeStep } from './CreateUserModeStep';
import { CreateNewUserStep } from './CreateNewUserStep';
import { CreateNewUserByEmailStep } from './CreateNewUserByEmailStep';
import { CreateNewUserByPhoneStep } from './CreateNewUserByPhoneStep';
import { CreateAssignUserStep } from './CreateAssignUserStep';
import { CreateDialogStepName, type Driver, type User } from '../../../../../../../types';
import {
    driverEmailSchema,
    driverPhoneNumberSchema,
    PROP_EMAIL,
    PROP_FIRST_NAME,
    PROP_LAST_NAME,
    PROP_PHONE_NUMBER,
    userFirstNameSchema,
    userLastNameSchema,
} from '../../../../../schema';
import { isEmpty, values } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import type { CreateUserDialogFormErrors, Steps } from '../../types/userTypes';
import { reportErrorToSentry } from '../../../../../../../configuration/setup/sentry';

type CreateUserDialogProperties = CreateUserDialogPropertiesFromState & CreateUserDialogPropertiesFromDispatch;

const initialUserState: User = {
    id: '',
    accountId: '',
    firstName: '',
    lastName: '',
};

export const isValidAssignableUser = (user: User | undefined): boolean => {
    return !!(user?.firstName && user?.lastName && (user?.email || user?.phoneNumber) && user?.subject);
};

export const CreateUserDialog = (props: CreateUserDialogProperties) => {
    const {
        showDialog,
        currentStep,
        selectedDriver,
        preferredLocale,
        goToStep,
        createUserAndSaveDriver,
        saveSelectedDriver,
        closeCreateUserDialog,
    } = props;

    const [userToBeInvited, setUserToBeInvited] = useState<User>(initialUserState);
    const [userToBeAssigned, setUserToBeAssigned] = useState<User | undefined>(undefined);
    const [formErrors, setFormErrors] = useState<CreateUserDialogFormErrors>({});

    useEffect(() => {
        if (currentStep === CreateDialogStepName.CREATE_NEW_USER) {
            const userFirstNameValidation = userFirstNameSchema.validate(selectedDriver?.firstName);
            const userLastNameValidation = userLastNameSchema.validate(selectedDriver?.lastName);
            setFormErrors({
                [PROP_FIRST_NAME]: userFirstNameValidation.error && 'intl-msg:error.firstNameUser.requiredAndMaxLength',
                [PROP_LAST_NAME]: userLastNameValidation.error && 'intl-msg:error.lastNameUser.requiredAndMaxLength',
            });
        } else if (currentStep === CreateDialogStepName.CREATE_USER_BY_PHONE) {
            const driverPhoneNumberValidation = driverPhoneNumberSchema.validate(selectedDriver?.phoneNumber);
            const userPhoneNumberValidation = driverPhoneNumberSchema.validate(userToBeInvited?.phoneNumber);
            setFormErrors({
                [PROP_PHONE_NUMBER]: driverPhoneNumberValidation.error
                    ? 'intl-msg:error.phoneNumber.invalid'
                    : userPhoneNumberValidation.error
                      ? 'intl-msg:error.phoneNumber.invalid'
                      : undefined,
            });
        } else if (currentStep === CreateDialogStepName.CREATE_USER_BY_EMAIL) {
            const driverEmailValidation = driverEmailSchema.validate(selectedDriver?.email);
            const userEmailValidation = driverEmailSchema.validate(userToBeInvited?.email);
            setFormErrors({
                [PROP_EMAIL]: driverEmailValidation.error
                    ? 'intl-msg:error.email.incorrect'
                    : userEmailValidation.error
                      ? 'intl-msg:error.email.incorrect'
                      : undefined,
            });
        } else {
            setFormErrors({});
        }
    }, [currentStep, selectedDriver, userToBeInvited]);

    useEffect(() => {
        if (selectedDriver) {
            setUserToBeInvited({
                id: uuidv4(),
                accountId: selectedDriver.accountId,
                firstName: selectedDriver.firstName,
                lastName: selectedDriver.lastName,
                email: selectedDriver.email,
                phoneNumber: selectedDriver.phoneNumber,
                preferredLanguage: preferredLocale,
            });
        }
    }, [selectedDriver, preferredLocale]);

    const updateState = (userProp: string, userValue: string, errorMessage: string | undefined) => {
        setUserToBeInvited(prevState => {
            return {
                ...prevState,
                [userProp]: userValue,
            };
        });
        setFormErrors(prevState => {
            return {
                ...prevState,
                [userProp]: errorMessage,
            };
        });
    };

    const onCreateUserClick = () => {
        goToStep(CreateDialogStepName.CREATE_NEW_USER);
    };

    const onCreateUserWithEmail = () => {
        goToStep(CreateDialogStepName.CREATE_USER_BY_EMAIL);
    };

    const onCreateUserWithPhone = () => {
        goToStep(CreateDialogStepName.CREATE_USER_BY_PHONE);
    };

    const onAssignUserClick = () => {
        goToStep(CreateDialogStepName.ASSIGN_USER);
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const { error } = userFirstNameSchema.validate(value);
        updateState(PROP_FIRST_NAME, value, error && 'intl-msg:error.firstNameUser.requiredAndMaxLength');
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const { error } = userLastNameSchema.validate(value);
        updateState(PROP_LAST_NAME, value, error && 'intl-msg:error.lastNameUser.requiredAndMaxLength');
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const { error } = driverEmailSchema.validate(value);
        updateState(PROP_EMAIL, value, error && 'intl-msg:error.email.incorrect');
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const { error } = driverPhoneNumberSchema.validate(value);
        updateState(PROP_PHONE_NUMBER, value, error && 'intl-msg:error.phoneNumber.invalid');
    };

    const isInvalidForm = (): boolean => {
        const hasErrors = !isEmpty(values(formErrors ? formErrors : {}).filter(Boolean));
        const completedFields = !!(
            userToBeInvited.firstName &&
            userToBeInvited.lastName &&
            (userToBeInvited.email || userToBeInvited.phoneNumber)
        );
        return hasErrors || !completedFields;
    };

    const createUser = () => {
        if (selectedDriver && (userToBeInvited.email || userToBeInvited.phoneNumber)) {
            createUserAndSaveDriver(userToBeInvited, selectedDriver);
        } else {
            reportErrorToSentry(
                'Create new user cannot be called without a selected driver and an email or a phone number.'
            );
        }
    };

    const handleUserSelected = (user: User) => {
        setUserToBeAssigned(user);
    };

    const handleUserDeselected = () => {
        setUserToBeAssigned(undefined);
    };

    const handleSaveDriver = () => {
        const driver: Driver | null = selectedDriver
            ? {
                  ...selectedDriver,
                  subject: userToBeAssigned?.subject,
                  user: userToBeAssigned,
              }
            : null;
        if (driver) {
            saveSelectedDriver(driver);
            setUserToBeAssigned(undefined);
        } else {
            reportErrorToSentry('Save driver is missing a selected driver.');
        }
    };

    const handleCloseCreateUserDialog = () => {
        if (selectedDriver) {
            setUserToBeInvited({
                id: uuidv4(),
                accountId: selectedDriver.accountId,
                firstName: selectedDriver.firstName,
                lastName: selectedDriver.lastName,
                email: selectedDriver.email,
                phoneNumber: selectedDriver.phoneNumber,
                preferredLanguage: preferredLocale,
            });
        } else {
            setUserToBeInvited(initialUserState);
        }
        setUserToBeAssigned(undefined);
        closeCreateUserDialog();
    };

    const getSteps = (): Steps => {
        const steps: Steps = {};
        steps[CreateDialogStepName.USER_MODE_SELECTION] = {
            headline: <FormattedMessage id={'intl-msg:assignUser'} />,
            body: <CreateUserModeStep onCreateUserClick={onCreateUserClick} onAssignUserClick={onAssignUserClick} />,
            showDialogBackButton: false,
            showDialogNextButton: false,
            backButtonText: '',
            nextButtonText: '',
            backClickHandler: () => {},
            nextClickHandler: () => {},
            disabled: false,
        };
        steps[CreateDialogStepName.CREATE_NEW_USER] = {
            headline: <FormattedMessage id={'intl-msg:assignUser'} />,
            body: (
                <CreateNewUserStep
                    onCreateUserWithEmail={onCreateUserWithEmail}
                    onCreateUserWithPhone={onCreateUserWithPhone}
                />
            ),
            showDialogBackButton: true,
            showDialogNextButton: false,
            backButtonText: <FormattedMessage id={'intl-msg:createUserDialog:back'} />,
            nextButtonText: '',
            backClickHandler: () => props.goToStep(CreateDialogStepName.USER_MODE_SELECTION),
            nextClickHandler: () => {},
            disabled: false,
        };
        steps[CreateDialogStepName.CREATE_USER_BY_EMAIL] = {
            headline: <FormattedMessage id={'intl-msg:assignUser'} />,
            body: (
                <CreateNewUserByEmailStep
                    handleFirstNameChange={handleFirstNameChange}
                    handleLastNameChange={handleLastNameChange}
                    handleEmailChange={handleEmailChange}
                    user={userToBeInvited}
                    formErrors={formErrors}
                />
            ),
            showDialogBackButton: true,
            showDialogNextButton: true,
            backButtonText: <FormattedMessage id={'intl-msg:createUserDialog:back'} />,
            nextButtonText: <FormattedMessage id={'intl-msg:createUserDialog:createUser'} />,
            backClickHandler: () => props.goToStep(CreateDialogStepName.CREATE_NEW_USER),
            nextClickHandler: createUser,
            disabled: isInvalidForm(),
        };
        steps[CreateDialogStepName.CREATE_USER_BY_PHONE] = {
            headline: <FormattedMessage id={'intl-msg:assignUser'} />,
            body: (
                <CreateNewUserByPhoneStep
                    handlePhoneChange={handlePhoneChange}
                    handleFirstNameChange={handleFirstNameChange}
                    handleLastNameChange={handleLastNameChange}
                    user={userToBeInvited}
                    formErrors={formErrors}
                />
            ),
            showDialogBackButton: true,
            showDialogNextButton: true,
            backButtonText: <FormattedMessage id={'intl-msg:createUserDialog:back'} />,
            nextButtonText: <FormattedMessage id={'intl-msg:createUserDialog:createUser'} />,
            backClickHandler: () => goToStep(CreateDialogStepName.CREATE_NEW_USER),
            nextClickHandler: createUser,
            disabled: isInvalidForm(),
        };
        steps[CreateDialogStepName.ASSIGN_USER] = {
            headline: <FormattedMessage id={'intl-msg:assignUser'} />,
            body: (
                <CreateAssignUserStep
                    assignedUser={userToBeAssigned}
                    onSuggestionSelected={handleUserSelected}
                    onUserRemoved={handleUserDeselected}
                />
            ),
            showDialogBackButton: true,
            showDialogNextButton: true,
            backButtonText: <FormattedMessage id={'intl-msg:createUserDialog:back'} />,
            nextButtonText: <FormattedMessage id={'intl-msg:save'} />,
            backClickHandler: () => goToStep(CreateDialogStepName.USER_MODE_SELECTION),
            nextClickHandler: handleSaveDriver,
            disabled: !isValidAssignableUser(userToBeAssigned),
        };
        return steps;
    };

    const activeStep = getSteps()[currentStep];
    const footer = (
        <div>
            {activeStep.showDialogBackButton ? (
                <button type={'button'} className={'float-left btn btn-default'} onClick={activeStep.backClickHandler}>
                    <span className={'rioglyph rioglyph-chevron-left'} aria-hidden={'true'} />
                    {activeStep.backButtonText}
                </button>
            ) : (
                <div />
            )}
            {activeStep.showDialogNextButton ? (
                <button
                    type={'button'}
                    className={'float-right btn btn-primary'}
                    onClick={activeStep.nextClickHandler}
                    disabled={activeStep.disabled}
                >
                    {activeStep.nextButtonText}
                </button>
            ) : (
                <div />
            )}
        </div>
    );

    return (
        <Dialog
            show={showDialog}
            title={activeStep.headline}
            body={activeStep.body}
            footer={footer}
            className={'exampleDialog'}
            bodyClassName={'padding-0'}
            bsSize={'sm'}
            showCloseButton={true}
            onHide={handleCloseCreateUserDialog}
        />
    );
};
