import type { PhraseId } from '../../../types';

export type DriverLicenseCountry = 'BRAZIL';
export interface DriverLicenseCountryData {
    prefix: string;
    postfix: string;
}
export const supportedCountries = new Map<DriverLicenseCountry, DriverLicenseCountryData>([
    [
        'BRAZIL',
        {
            prefix: 'BR 000',
            postfix: '00',
        },
    ],
]);

export const getCountrySpecificIdentification = (country: DriverLicenseCountry, licenseNumber: string) => {
    const countryData = supportedCountries.get(country);
    if (countryData !== undefined && licenseNumber) {
        return countryData.prefix + licenseNumber + countryData.postfix;
    } else {
        return null;
    }
};

export const phraseLicenseCountry = (country: DriverLicenseCountry): PhraseId => {
    return `intl-msg:createDriver.addDriver.dialog.driverLicense.issuingCountry.${country.toString()}` as PhraseId;
};
