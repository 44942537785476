import { reportErrorToSentry } from '../configuration/setup/sentry';

export interface ErrorResponse {
    status: number;
    detail: string | null;
}

export enum ErrorOrigin {
    DRIVER_ADMINISTRATION = 0,
    TAG_SERVICE = 1,
    USER_ADMINISTRATION = 2,
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const filterNonEmpty = (_: any, value: any) => {
    if (value === null || value === '') {
        return undefined;
    }
    return value;
};

export const jsonOrReject = (response: Response) => {
    if (response.status === 401 || response.status === 403) {
        return Promise.reject({ status: response.status });
    } else if (response.status >= 400 && response.status < 500) {
        return response.json().then(errorResponse =>
            Promise.reject({
                status: errorResponse.status,
                detail: errorResponse.detail,
            })
        );
    } else if (response.ok) {
        return response
            .json()
            .then(json => ({
                body: json,
                headers: response.headers,
            }))
            .catch(error => {
                reportErrorToSentry(new Error(`${response.status} Invalid payload: ${error.message}`));
            });
    }

    return Promise.reject({ status: response.status });
};

export const rejectFailed = (response: Response) => {
    if (response.status === 401 || response.status === 403) {
        return Promise.reject({ status: response.status });
    } else if (response.status >= 400 && response.status < 500) {
        return response.json().then(errorResponse =>
            Promise.reject({
                status: errorResponse.status,
                detail: errorResponse.detail,
            })
        );
    } else if (!response.ok) {
        return Promise.reject({ status: response.status });
    }
    return Promise.resolve(response);
};

export const getErrorCode = (errorResponse: ErrorResponse, errorOrigin: ErrorOrigin) => {
    if (errorResponse.status === 403 || errorResponse.status === 401) {
        return 'UNAUTHORIZED';
    }
    const errorCodeRegex = /^\[(.+)]:.*$/;
    const matches = errorResponse.detail && errorCodeRegex.exec(errorResponse.detail);
    const errorCode = matches && matches.length === 2 ? matches[1] : null;

    if (errorCode === null) {
        switch (errorOrigin) {
            case ErrorOrigin.DRIVER_ADMINISTRATION:
                return 'FXX';
            case ErrorOrigin.TAG_SERVICE:
                return 'TXX';
            case ErrorOrigin.USER_ADMINISTRATION:
                return 'UXX';
        }
    }

    return errorCode;
};
