import type React from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';

interface TableHeadProperties {
    field: string;
    text: string | React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    sortBy?: string;
    sortDir?: string;
}

export const TableHead = memo((props: TableHeadProperties) => {
    const { field, text, onClick, sortBy, sortDir } = props;
    const classes = classNames('user-select-none', sortBy && 'sort-column');

    return (
        <th className={classes} data-field={field} data-sortby={sortBy} onClick={onClick}>
            <span>
                {sortBy ? <SortArrows direction={sortDir} /> : <SortArrows />}
                {text}
            </span>
        </th>
    );
});
