import type { Driver, Tag, TagToCreate } from '../../../types';
import { ErrorOrigin, type ErrorResponse, getErrorCode } from '../../apiUtils';
import { showErrorNotification, showSuccessNotification } from '../notifications/notifications';
import { fetchDrivers, fetchUsers } from './fetchThunks';
import { createTag, tagDriver, untagDriver } from './tagThunks';
import type { AppDispatch } from '../../../configuration/setup/store';
import { clearAllMultiSelectTags } from '../drivers/sidebar/tabs/groups/groupSlice';

export async function addExistingTagsToDriver(tagsToAdd: Tag[], driverId: string) {
    for (const tag of tagsToAdd) {
        await tagDriver(tag.id, driverId);
    }
}

export async function removeTagsFromDriver(tagsToRemove: Tag[], driverId: string) {
    for (const tag of tagsToRemove) {
        await untagDriver(tag.id, driverId);
    }
}

export function createNewTagsInAccount(tagsToCreate: TagToCreate[], accountId: string) {
    return Promise.all(tagsToCreate.map(tag => createTag(tag.name, accountId)));
}

export async function createNewTagsAndAddToDriver(tagsToCreate: TagToCreate[], driverId: string, accountId: string) {
    const createdTags = await createNewTagsInAccount(tagsToCreate, accountId);
    await addExistingTagsToDriver(createdTags, driverId);
}

export const addTagsToDrivers =
    (driverIds: string[], accountId: string, tagsToCreate: TagToCreate[], tagsToAdd: Tag[]) =>
    async (dispatch: AppDispatch) => {
        const newlyCreatedTags = await createNewTagsInAccount(tagsToCreate, accountId).catch(
            (errorResponse: ErrorResponse) => {
                showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.TAG_SERVICE));
            }
        );
        const allTagsToAdd = newlyCreatedTags ? tagsToAdd.concat(newlyCreatedTags) : tagsToAdd;
        for (const driverId of driverIds) {
            await addExistingTagsToDriver(allTagsToAdd, driverId)
                .then(() => {
                    showSuccessNotification('intl-msg:save.success');
                })
                .catch((errorResponse: ErrorResponse) => {
                    showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
                });
        }
        // fetch list of drivers again as the state may have changed
        await dispatch(fetchUsers());
        await dispatch(fetchDrivers());

        await dispatch(clearAllMultiSelectTags());
    };

export const removeTagsFromDrivers = (drivers: Driver[], tagsToRemove: Tag[]) => async (dispatch: AppDispatch) => {
    for (const driver of drivers) {
        const tagsToRemoveFromThisDriver = tagsToRemove.filter(tagToRemove => driver.tags?.includes(tagToRemove.id));
        if (tagsToRemoveFromThisDriver.length > 0) {
            await removeTagsFromDriver(tagsToRemoveFromThisDriver, driver.driverId)
                .then(() => {
                    showSuccessNotification('intl-msg:save.success');
                })
                .catch((errorResponse: ErrorResponse) => {
                    showErrorNotification(getErrorCode(errorResponse, ErrorOrigin.DRIVER_ADMINISTRATION));
                });
        }
    }
    await dispatch(fetchUsers());
    await dispatch(fetchDrivers());
    await dispatch(clearAllMultiSelectTags());
};
